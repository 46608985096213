
.info-box {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.info-box .text-wrapper {
    order: 1
}

.info-box .text-wrapper h3 {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 1em;
}

.info-box .text-wrapper .info-description p,
.info-box .text-wrapper .info-description {
    font-size: 16px;
    font-weight: 100;
}

.info-box .infobox-image {
    margin-bottom: 10px
}

.info-box .infobox-box-link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 40
}
@media screen and (max-width: 768px) {
    .info-box .text-wrapper .info-description p,
    .info-box .text-wrapper .info-description {
        font-size: 14px;;
    }
}
