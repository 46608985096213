@tailwind base;
@tailwind components;
@tailwind utilities;


@import url(https://use.typekit.net/cpc0qjx.css);


:root {
    --sb-color-black: #000;
    --sb-color-black-pearl: #000d18;
    --sb-color-white: #fff;
    --sb-color-dark-blue: #00213e;
    --sb-color-light-blue: #33658a;
    --sb-color-light-grey: #e8e8e8;

    --left-line-bg: 32%;
    --right-line-bg: 32%;

    --primary: transparent;
    --secondary: rgb(127, 198, 164);
}

/* Firefox */
*{
    scrollbar-width: thin;
    scrollbar-color: var(--primary) var(--secondary);
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }
  
  *::-webkit-scrollbar-track {
    background: var(--primary);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
  /*   background: repeating-linear-gradient(
      45deg,
      var(--secondary),
      var(--secondary) 5px,
      var(--primary) 5px,
      var(--primary) 10px
    ); */
    border-radius: 20px;
    border: 3px solid var(--primary);
  }
  

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: Capitana;
    letter-spacing: 1px;
    line-height: 2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.5;
}

h1 {
    font-size: 56px
}

h1,
h2 {
    font-weight: 900
}

h2 {
    font-size: 40px
}

h3 {
    font-size: 36px
}

h4 {
    font-size: 30px
}

h5 {
    font-size: 28px
}

p {
    color: var(--sb-color-black);
}

.bg-white-half {
    background: linear-gradient(180deg, var(--sb-color-white) 0, var(--sb-color-white) 50%, transparent 0)
}

p {
    font-size: 18px
}

@keyframes page-fade {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#root .wrapper main {
    animation: page-fade .1s;
    animation-fill-mode: forwards;
}

#root > .wrapper > header {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 20;
    background: linear-gradient(to bottom,rgba(0,0,0,0.95) 0,rgba(0,0,0,0.3) 76%,rgba(0,0,0,0) 95%,rgba(0,0,0,0) 100%)!important;
}

#root header nav {
    position: relative
}

#root footer ul:not(.footer-menu) li a,
#root header nav .menu li a {
    color: var(--sb-color-white);
    font-size: 11.5px;
    font-weight: 900;
    position: relative;
    text-transform: uppercase;
}

@media screen and (min-width: 1280px) {

    #root footer ul:not(.footer-menu) li a,
    #root header nav .menu li a {
        font-size: 13px;
    }
}

#root header nav .menu li a.contact {
    background: var(--sb-color-white);
    border: 1px solid var(--sb-color-white);
    box-shadow: inset 0 0 0 3px var(--sb-color-dark-blue);
    color: var(--sb-color-black);
    padding: 10px 30px
}

#root header nav .menu li a.contact:hover {
    background: linear-gradient(113.49deg, #7fc6a4, var(--sb-color-light-blue));
    color: var(--sb-color-white)
}

#root header nav .menu li a.contact:hover:after {
    content: normal
}

#root footer ul:not(.footer-menu) li a:after,
#root header nav .menu li a:after {
    background-color: var(--sb-color-light-blue);
    background-color: var(--sb-color-light-blue);
    bottom: -5px;
    content: "";
    height: 3px;
    left: 0;
    opacity: 0;
    position: absolute;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center;
    transform-origin: center;
    transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s;
    transition: opacity .3s, transform .3s, -webkit-transform .3s;
    width: 100%
}

#root footer ul:not(.footer-menu) li a:hover:after,
#root header nav .menu li a:hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

#root header .menu-wrapper .socials,
#root header .menu-wrapper .mobile-logo-header,
#root footer .menu-wrapper .socials,
#root footer .menu-wrapper .mobile-logo-header {
    display: none;
}

main .sb-button-wrapper {
    display: block;
    padding: 20px 0
}

main .sb-button-wrapper .sb-custom-button,
main .sb-button-wrapper .submit-button {
    color: var(--sb-color-light-blue);
    font-family: capitana;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2px;
    padding: 10px 70px 10px 0;
    position: relative
}

main .sb-button-wrapper .sb-custom-button:hover {
    color: var(--sb-color-black)
}

main .sb-button-wrapper .sb-custom-button.white-color {
    color: var(--sb-color-white)
}

main .sb-button-wrapper .sb-custom-button:after,
main .sb-button-wrapper .submit-button:after {
    background-image: url(./images/rings-dark.svg);
    content: "";
    height: 50px;
    opacity: .3;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-47%) rotate(0deg);
    transform: translateY(-47%) rotate(0deg);
    transition: all .3s linear;
    width: 50px
}

main .sb-button-wrapper .sb-custom-button span,
main .sb-button-wrapper .submit-button span {
    position: relative
}

main .sb-button-wrapper .sb-custom-button span:before,
main .sb-button-wrapper .submit-button span:before {
    background-color: var(--sb-color-black);
    bottom: -6px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out, -webkit-transform .25s ease-out;
    width: 100%
}

main .sb-button-wrapper .sb-custom-button.white-color span:before {
    background-color: var(--sb-color-white)
}

main .sb-button-wrapper .sb-custom-button:hover span:before,
main .sb-button-wrapper .submit-button:hover span:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left
}

main .sb-button-wrapper .sb-custom-button.white-color:after {
    -webkit-filter: invert(97%) sepia(100%) saturate(0) hue-rotate(108deg) brightness(124%) contrast(101%);
    filter: invert(97%) sepia(100%) saturate(0) hue-rotate(108deg) brightness(124%) contrast(101%)
}

main .sb-button-wrapper .sb-custom-button:before,
main .sb-button-wrapper .submit-button:before {
    background-image: url(./images/button-arrow.svg);
    background-position: 100%;
    content: "";
    height: 8px;
    position: absolute;
    right: 22px;
    top: 50%;
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    transition: all .2s linear;
    width: 42px
}

main .sb-button-wrapper .sb-custom-button.white-color:before {
    -webkit-filter: invert(1);
    filter: invert(1)
}

main .sb-button-wrapper .sb-custom-button:hover {
    background-size: 0 .1em, 75% .2em
}

main .sb-button-wrapper .sb-custom-button:hover:before,
main .sb-button-wrapper .submit-button:hover:before {
    width: 4px
}

main .sb-button-wrapper .sb-custom-button:hover:after,
main .sb-button-wrapper .submit-button:hover:after {
    opacity: 1;
    -webkit-transform: translateY(-47%) rotate(180deg);
    transform: translateY(-47%) rotate(180deg)
}

main .sb-button-wrapper .sb-custom-button.more-info:hover:before {
    -webkit-transform: translate(83px, -30%);
    transform: translate(83px, -30%)
}


main .sb-button-wrapper .sb-custom-button.more-info,
main .sb-button-wrapper .sb-custom-button.more-info span {
    background: none;
    color: hsla(0, 0%, 100%, 0);
    font-size: 10px;
    font-weight: 700;
    transition: color .3s ease-in-out;
    transition-delay: .1s;
    vertical-align: middle
}

main .sb-button-wrapper .sb-custom-button.more-info span:before {
    content: normal
}

main .sb-button-wrapper .sb-custom-button.more-info:hover,
main .sb-button-wrapper .sb-custom-button.more-info:hover span {
    color: var(--sb-color-white)
}

main .sb-button-wrapper .sb-custom-button.more-info:after {
    content: normal
}

main .sb-button-wrapper .sb-custom-button.more-info:before {
    right: auto
}

main .sb-custom-button.column-btn {
    color: var(--sb-color-white);
    padding: 10px 10px 10px 0;
    text-transform: uppercase
}

main .sb-button-wrapper .sb-custom-button.column-btn:before {
    content: normal
}

main .sb-button-wrapper .sb-custom-button.column-btn:after {
    background-image: none;
    border-bottom: 5px solid transparent;
    border-left: 5px solid var(--sb-color-white);
    border-top: 5px solid transparent;
    content: "";
    height: 5px;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 5px
}

/* END BUTTONS */


#root .sb-columns {
    align-items: center;
    display: flex;
}

#root .sb-columns .sb-column:not(:only-child) {
    flex-basis: 50%;
    min-width: 0;
    overflow-wrap: break-word;
    word-break: break-word;
}

.sb-hero {
    --hero-margin-top: 100px;
}

/* Medium and large screens */
@media screen and (min-width: 768px) {
    .sb-hero {
        --hero-margin-top: 175px;
    }
}

.sb-hero {
    background-position: 50%;
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl) 0%, var(--sb-color-dark-blue) 100%);
    box-sizing: border-box;
    /* min-height: 640px; */
    overflow: hidden;
    padding: var(--hero-margin-top) 0 3em;
    position: relative;
}

.sb-hero.hero-home:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, .95) 0, transparent 100%, transparent 0, transparent);
    content: "";
    height: 175px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

@media screen and (min-width: 768px) {
    .sb-hero.contact {
        padding-bottom: 6em;
    }
}

/* .sb-hero.web-app-design {
    background: linear-gradient(69deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue) 69%, #7fc6a4 0, var(--sb-color-light-blue) 89%);
} */

.sb-hero.web-app-design .animated {
    max-width: unset !important;
}

.sb-hero.web-app-design .animated .design-icons {
    max-width: 600px;
}

/* Large screens */
@media screen and (min-width: 1024px) {

    .sb-hero.web-app-design .animated .design-icons {
        width: 67%;
    }
}

.sb-hero.web-app-design .animated .design-icons .icon-wrapper img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: .7;
    transition: all .3s linear;
    max-width: 65%;
}

.sb-hero.web-app-design .animated .design-icons .icon-wrapper.active img {
    -webkit-filter: initial;
    filter: none;
    opacity: 1
}

@media screen and (max-width: 1023px) {
    .sb-hero.web-app-design .animated .design-images {
        display: none;
    }
}

.sb-hero.web-app-design .animated .design-images div {
    /* Positioned absolutely within .sb-hero-inner-container */

    /* --design-image-offset = The halfway point between the window width and .sb-hero-inner-container width, or 0 if they are the same */
    --design-image-offset: calc((100vw - 100%) / 4);
    /* --design-image-width = 30% of the .sb-hero-inner-container width plus --design-image-offset */
    --design-image-width: calc(var(--design-image-offset) + 30%);

    align-items: center;
    bottom: -1.5em;
    display: flex;
    justify-content: center;
    opacity: 0.5;
    right: calc((100% - 100vw) / 2 - var(--design-image-width));
    width: var(--design-image-width);
    position: absolute;
    top: -50px;
    transition: opacity 0.6s ease-in, right 0.6s cubic-bezier(.73, .71, 0, 1.15);
}

.sb-hero.web-app-design .animated .design-images div.active {
    opacity: 1;
    /* right = negative --design-image-offset with 1em padding */
    right: calc(-1 * var(--design-image-offset) + 1em);
}

.sb-hero.web-app-design .animated .design-images div img {
    max-height: 100%;
    max-width: 100%;
}

.sb-hero.web-app-design .description-wrapper {
    display: grid;
}

.sb-hero.web-app-design .description-wrapper .description-row {
    grid-column: 1;
    grid-row: 1;
    opacity: 0;
    transition: opacity .3s linear
}

.sb-hero.web-app-design .description-wrapper .description-row.active {
    opacity: 1
}

.sb-hero.web-app-design .description-wrapper .description-row h4 {
    color: var(--sb-color-white);
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 900;
}

.sb-hero .sb-hero-background-image,
.sb-hero .sb-hero-background-video {
    border: none;
    bottom: 0;
    box-shadow: none;
    height: 100%;
    left: 0;
    margin: calc(-1 * var(--hero-margin-top)) 0 -3em;
    max-height: none;
    max-width: none;
    object-fit: none;
    object-position: 100% 100%;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.sb-hero.hero-about .sb-hero-background-image {
    margin: auto;
    object-fit: contain;
}


.sb-hero .sb-hero-background-video {
    -o-object-fit: cover;
    object-fit: cover;
}

.sb-hero.hero-home .sb-hero-background-video {
    position: relative;
}

.sb-hero .sb-hero-inner-container {
    position: relative;
    /* width: calc(100% - 4em); */
}

/* Large screens */
@media screen and (min-width: 1024px) {

    .sb-hero.web-app-design .sb-hero-inner-container > * {
        max-width: 67%;
    }
}

.sb-hero .sb-hero-inner-container h1,
.sb-hero .sb-hero-inner-container h2 {
    color: var(--sb-color-white)
}

.sb-hero .sb-hero-inner-container h1 {
    font-size: 40px;
}

.sb-hero .sb-hero-inner-container p {
    color: var(--sb-color-white);
    font-size: 18px
}

.sb-hero:not(.hero-animated) .sb-hero-inner-container h1:not(:empty) + p:not(:empty) {
    margin-top: 30px;
}

.sb-hero.hero-animated .sb-hero-inner-container p + p {
    margin-top: 2em
}

main .common-section {
    padding: 3em 0;
}

#root main .initial-section h2 {
    color: #b5b5b5;
    font-size: 56px;
    font-weight: 100;
    margin-bottom: 30px
}

#root main .initial-section h2 b {
    font-weight: 900
}

#root main .initial-section p {
    margin-bottom: 30px
}

.hero-home .sb-columns {
    position: relative
}

.sb-hero .sb-columns:not(.animated) .sb-column:nth-child(2) img {
    position: absolute;
    top: -154px
}
@media screen and (min-width: 1921px) {
    .sb-hero .sb-columns:not(.animated) .sb-column:nth-child(2) img {
        right: calc(-50vw + 50%);
    }
}


.sb-hero.integrations .sb-columns:not(.animated) .sb-column:nth-child(2) img {
    top: 15%;
    max-width: 80%;
}
.sb-hero.integrations .sb-columns:not(.animated) .sb-column:nth-child(1) {
    flex-basis: 40%;
}
.sb-hero.websites .sb-columns:not(.animated) .sb-column:nth-child(2) img {
    bottom: -154px;
    top: auto
}

.support-section {
    background-color: var(--sb-color-light-grey);
    background-color: var(--sb-color-light-grey)
}

main .support-section .heading h2 {
    margin-bottom: 1em !important;
}

.support-section .sub-heading p {
    margin-bottom: 0.5em
}

.support-section .info-box-wrapper {
    justify-content: space-between;
    padding: 3em 0
}

.support-section .info-box-wrapper .info-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    text-align: center;
    align-items: flex-end;
}

.support-section .info-box-wrapper .info-box img {
    margin-bottom: 15px
}

.support-section .info-box-wrapper .info-box .text-wrapper {
    flex-basis: 100%;
    order: 1
}

.support-section .info-box-wrapper .info-box h3 {
    font-size: 16px;
    font-weight: 700
}

/* Extra extra large screens */
@media screen and (max-width: 2170px) {
    main .ourmission-section {
        --left-line-bg: 30.1%;
        --right-line-bg: 28%;
    }
}

xxxxmain .ourmission-section {
    /* background: linear-gradient(118deg, var(--sb-color-light-blue), #7fc6a4 34%, var(--sb-color-black-pearl) 0, var(--sb-color-dark-blue) 0); */
    background: linear-gradient(111deg, var(--sb-color-light-blue), #7fc6a4 calc(var(--left-line-bg) - 1px), #aaa, var(--sb-color-dark-blue) calc(var(--right-line-bg) + 1px));
    padding: 0
}
main .ourmission-section {
    background: linear-gradient(118deg, var(--sb-color-light-blue), #7fc6a4 45%, var(--sb-color-black-pearl) 0, var(--sb-color-dark-blue) 0);
    padding: 0;
}


main .ourmission-section .columns {
    position: relative;
    width: 100%
}

xxxmain .ourmission-section .ourmission-container .leftcol {
    flex-basis: 35%;
    /* clip-path: polygon(0px 0, 424px 0px, 245px 365px, 0px 783px); */
    /* clip-path: polygon(0px 0, 350px 0px, 225px 320px, 0px 820px); */
    clip-path: url(#clip-polygon);
    display: flex;
    align-items: center;
}

main .ourmission-section .ourmission-container .leftcol {
    flex-basis: 35%;
    transform: skew(-20deg);
    overflow: hidden;
}

main .ourmission-section .ourmission-container .leftcol h2 {
    font-size: 56px;
    font-weight: 900;
    position: relative;
    z-index: 40;
    left: 0;
    top: 120px;
}

main .ourmission-section .ourmission-container .leftcol .logo-wrapper { 
    transform: skewX(20deg) translateY(-15%);
    position: absolute;
    width: 100%;
    top: 15%;
    left: 0;
}

main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo {
    -webkit-animation: spin-logo 15s infinite;
    animation: spin-logo 15s infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    left: 0;
    opacity: .35;
    position: absolute;
    top: 0
}

main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo.middle {
    animation-direction: reverse;
    left: 70px;
    top: 70px
}

main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo.inner {
    left: 128px;
    top: 128px
}

main .ourmission-section .ourmission-container .leftcol:after {
    background-image: url(./images/spinning-logo.svg);
    height: 464px;
    position: absolute;
    top: -180px;
    width: 464px
}

main .ourmission-section .ourmission-container .leftcol:before {
    background: #072239;
    height: 470px;
    position: absolute;
    right: -114px;
    top: -180px;
    -webkit-transform: skew(332deg, 4deg);
    transform: skew(332deg, 4deg);
    width: 252px;
    z-index: 40
}

xxxmain .ourmission-section .ourmission-container .rightcol {
    flex-basis: 80%;
    position: relative;
    z-index: 50
}
main .ourmission-section .ourmission-container .rightcol {
    transform: skew(-20deg);
    background: var(--sb-color-dark-blue);
    flex-basis: 80%;
    position: relative;
    z-index: 50;
    margin-left: -120px;
    padding: 3em 0 3em 10em;
}

main .ourmission-section .ourmission-container .rightcol .inner-col {
    transform: skew(20deg);
}

main .ourmission-section .ourmission-container .rightcol .counters span {
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(#7fc6a4, var(--sb-color-light-blue));
    -webkit-background-clip: text;
    font-size: 56px;
    font-weight: 900
}

@-webkit-keyframes spin-logo {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes spin-logo {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.clients-section {
    padding: 3em 0
}
.clients-section .sb-button-wrapper {
    margin-bottom: 2em;
}

.clients-section.clients-bottom {
    padding-top: 0
}

.clients-section.clients-top {
    padding-bottom: 0
}

.clients-section.clients-bottom .info-box-wrapper .info-box:first-child {
    display: none;
}

main .mobile-apps-copy {
    background-color: var(--sb-color-light-grey);
    background-color: var(--sb-color-light-grey)
}

main .solutions-section {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    padding: 5em 0
}

main .solutions-section .heading h2 {
    color: var(--sb-color-white);
    margin-bottom: 0.5em !important;
}

main .solutions-section .sub-heading p {
    color: var(--sb-color-light-blue);
    margin-bottom: 2em;
}
main .solutions-section .info-box-wrapper {
    gap: 0 3em;
}
main .solutions-section .info-box {
    flex-wrap: nowrap;
    align-items: center;
    flex-basis: 50%;
}

main .solutions-section .info-box:hover .sb-custom-button.more-info,
main .solutions-section .info-box:hover .sb-custom-button.more-info span {
    color: var(--sb-color-white)
}

main .solutions-section .info-box:hover .sb-custom-button.more-info:before {
    -webkit-filter: invert(1);
    filter: invert(1);
    -webkit-transform: translate(83px, -30%);
    transform: translate(83px, -30%);
    width: 4px
}

main .solutions-section .info-box .text-wrapper {
    flex-basis: auto;
    margin-left: 2em;
}

main .solutions-section .info-box .infobox-heading {
    color: var(--sb-color-white);
    font-size: 20px;
    font-weight: 900;
    max-width: 75%
}

main .solutions-section .info-box.Android img {
    max-width: 250px
}

main .solutions-section .info-box img {
    margin-bottom: 0;
}

main .solutions-section .info-box .info-description {
    color: var(--sb-color-white);
}

xxxmain .solutions-section .info-box-wrapper {
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(113.49deg, #7fc6a4, var(--sb-color-light-blue));
    border-left: 0;
    border-right: 0;
    border-top: 0
}

main .solutions-section .column-wrapper.two-columns {
    flex-wrap: wrap
}

main .solutions-section .column-wrapper.two-columns .info-box {
    flex-basis: 45%
}

main .solutions-section .column-wrapper .column h3 {
    color: var(--sb-color-white);
    font-size: 20px;
    margin-bottom: 20px
}

main .solutions-section .column-wrapper .column .copy p {
    color: var(--sb-color-white);
    font-weight: 100;
    font-size: 16px
}

main .the-search-is-over .column:hover .sb-custom-button.more-info,
main .the-search-is-over .column:hover .sb-custom-button.more-info span {
    color: var(--sb-color-white)    
}

main .the-search-is-over .info-box-wrapper {
    gap: 0 3em;
}
main .the-search-is-over .info-box-wrapper .info-box {
    color: var(--sb-color-white);
}

main .the-search-is-over .column:hover .sb-custom-button.more-info:before {
    -webkit-filter: invert(1);
    filter: invert(1);
    -webkit-transform: translate(83px, -30%);
    transform: translate(83px, -30%);
    width: 4px
}
main .the-search-is-over .column-wrapper {
    margin-top: 2em;
}

main .websites.our-process {
    padding-top: 2em;
}

main .team-quality-section {
    background: linear-gradient(90deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue) 50%, #7fc6a4 0, var(--sb-color-light-blue) 89%);
    padding: 3em 0
}

main .team-quality-section .columns .column {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 5em
}

main .team-quality-section .columns .column:nth-child(2) {
    padding-left: 5em;
    padding-right: 0
}

main .team-quality-section .columns .column h2 {
    color: var(--sb-color-white)
}

main .team-quality-section .columns .column .team-quality-copy p {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 100;
}
main .team-quality-section .columns .column .info-box-wrapper {
    padding-top: 2em;
}
main .team-quality-section .columns .column .info-box {
    display: flex;
    flex-basis: 45%;
    flex-wrap: wrap;
    align-items: flex-end;
}

main .team-quality-section .columns .column .info-box .text-wrapper {
    flex-basis: 100%;
    order: 1
}

main .team-quality-section .columns .column .info-box h3 {
    color: var(--sb-color-white);
    font-size: 22px;
    font-weight: 100;
    margin-top: 20px
}


main .grey-common-section {
    background-color: var(--sb-color-light-grey);
    background-color: var(--sb-color-light-grey)
}

main .grey-common-section p {
    color: var(--sb-color-light-blue);
    color: var(--sb-color-light-blue)
}

main .pop-features-section {
    padding: 3em 0
}

main .pop-features-section .info-box-wrapper .info-box {
    display: flex;
    flex-basis: 30%;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;
    transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out
}

main .pop-features-section .info-box-wrapper .info-box:before {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .3s linear;
    width: 100%
}

main .pop-features-section .info-box-wrapper .heading {
    flex-basis: 30%;
    font-size: 36px;
    font-weight: 900
}

main .pop-features-section .info-box-wrapper .info-box h3 {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 10px
}

main .pop-features-section .info-box-wrapper .info-box .text-wrapper {
    order: 1;
    position: relative
}

main .pop-features-section .info-box-wrapper .info-box img {
    margin-bottom: 10px;
    position: relative
}

main .pop-features-section .info-box-wrapper .info-box .info-description {
    opacity: 0;
    transition: all .3s linear
}

main .pop-features-section .info-box-wrapper .info-box:hover {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px)
}

main .pop-features-section .info-box-wrapper .info-box:hover:before {
    opacity: 1
}

main .pop-features-section .info-box-wrapper .info-box:hover .info-description p,
main .pop-features-section .info-box-wrapper .info-box:hover .info-description,
main .pop-features-section .info-box-wrapper .info-box:hover .infobox-heading {
    color: var(--sb-color-white)
}

main .pop-features-section .info-box-wrapper .info-box:hover .info-description {
    opacity: 1
}

main .column-section {
    padding: 5em 0
}

main .common-section.pro-tips {
    padding: 4em 0;
    background-color: var(--sb-color-light-grey)
}

main .column-section .heading {
    font-size: 36px;
    font-weight: 700;
}

main .common-section .heading h2 {
    margin-bottom: 2em
}

main .column-section .sub-heading {
    color: var(--sb-color-light-blue);
    margin-bottom: 1em
}

main .common-section.pro-tips .column-wrapper .column {
    padding-left: 60px;
    padding-top: 0;
    position: relative
}

main .common-section.pro-tips .column-wrapper .column .column-heading {
    display: none;
}

main .common-section.pro-tips .column-wrapper .column:before {
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(#7fc6a4, var(--sb-color-light-blue));
    -webkit-background-clip: text;
    content: "1";
    font-size: 110px;
    font-weight: 100;
    left: 0;
    line-height: 110px;
    position: absolute;
    top: 0
}

main .common-section.pro-tips .column-wrapper .column:nth-child(2):before {
    content: "2";
    left: -20px
}

main .common-section.pro-tips .column-wrapper .column:nth-child(3):before {
    content: "3";
    left: -20px
}

main .common-section.pro-tips .column-wrapper .column .column-heading {
    font-size: 30px;
    font-weight: 900
}
main .common-section.pro-tips .column-wrapper .column .copy p {
    line-height: 38px;
}

main .generic-section {
    padding: 4em 0
}

main .generic-section .heading h2 {
    color: #b5b5b5;
    font-size: 56px;
    font-weight: 100;
    margin-bottom: 30px
}

main .generic-section .heading h2 b {
    font-weight: 900
}

main .common-section.maintenance-page .info-box-wrapper {
    margin-top: 80px;
    gap: 1.5em;
}

main .common-section.maintenance-page .info-box {
    align-content: flex-start;
    padding: 0px 1.5em 1.5em 1.5em;
    position: relative;
    transition: all .3s linear
}

main .common-section.maintenance-page .info-box:hover {
    margin-bottom: -10px;
    margin-top: -50px;
    padding-top: 30px
}

main .common-section.maintenance-page .info-box:before {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    content: "";
    height: 28%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: top .4s linear, opacity .7s ease-in-out;
    width: 2px
}

main .common-section.maintenance-page .info-box:hover:before {
    opacity: 0;
    top: 50%
}

main .common-section.maintenance-page .info-box:after {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .3s linear;
    transition-delay: .2s;
    width: 100%
}

main .common-section.maintenance-page .info-box:hover:after {
    opacity: 1
}

main .common-section.maintenance-page .info-box .infobox-image,
main .common-section.maintenance-page .info-box .text-wrapper {
    position: relative;
    z-index: 30
}

main .common-section.maintenance-page .info-box:hover .text-wrapper .info-description p,
main .common-section.maintenance-page .info-box:hover .text-wrapper .info-description,
main .common-section.maintenance-page .info-box:hover .text-wrapper h3 {
    color: var(--sb-color-white)
}

main .common-section.maintenance-page .info-box .text-wrapper .info-description {
    opacity: 0;
    transition: opacity .5s linear;
    line-height: 40px;
    font-weight: 100;
}

main .common-section.maintenance-page .info-box:hover .text-wrapper .info-description {
    opacity: 1;
}


main .common-section.web-hosting .column-wrapper {
    padding-top: 3em
}

main .common-section.web-hosting .column-wrapper .column {
    border-left: 4px solid var(--sb-color-light-blue);
    flex-basis: 30%;
    padding: 1.5em;
}

main .common-section.web-hosting .column-wrapper .column::before {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
    animation-duration: .4s;
    animation-fill-mode: forwards;
    animation-name: background-slide-out;
    animation-timing-function: ease-out
}

main .common-section.web-hosting .column-wrapper .column:hover::before {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: background-slide-in;
    animation-name: background-slide-in;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
}

main .common-section.web-hosting .column-wrapper .column .column-heading,
main .common-section.web-hosting .column-wrapper .column .copy {
    position: relative
}

#root main .common-section.web-hosting .column-wrapper .column .copy p:nth-child(2) {
    margin-bottom: 0;
}

main .common-section.web-hosting .column-wrapper .column:hover .column-heading,
main .common-section.web-hosting .column-wrapper .column:hover .copy p {
    color: var(--sb-color-white)
}

main .common-section.web-hosting .column-wrapper .column .copy p code {
    color: var(--sb-color-light-blue);
    display: block;
    font-family: capitana;
    font-size: 20px;
    margin-top: 10px
}

main .common-section.web-hosting .column-wrapper .column:hover .copy p code {
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(#7fc6a4, var(--sb-color-light-blue));
    -webkit-background-clip: text;
    font-family: capitana;
    font-size: 20px
}

main .common-section.web-hosting .column-wrapper .column .sb-button-wrapper {
    display: flex !important;
    justify-content: flex-end;
    margin-top: -60px;
    opacity: 0;
    transition: opacity .4s linear
}

main .common-section.web-hosting .column-wrapper .column .sb-button-wrapper a:hover {
    color: var(--sb-color-white);
}

main .common-section.web-hosting .column-wrapper .column .sb-button-wrapper .sb-custom-button span:before {
    background-color: var(--sb-color-white);
}

main .common-section.web-hosting .column-wrapper .column:hover .sb-button-wrapper {
    opacity: 1
}

main .common-section.wp-benefits .column-wrapper {
    align-items: center;
}

main .common-section.wp-benefits .column-wrapper .column {
    flex-basis: 50%;
}

main .common-section.wp-solutions {
    background-color: var(--sb-color-light-grey);
}

@-webkit-keyframes background-slide-in {
    0% {
        width: 0
    }

    80% {
        width: 105%
    }

    to {
        width: 100%
    }
}

@keyframes background-slide-in {
    0% {
        width: 0
    }

    80% {
        width: 105%
    }

    100% {
        width: 100%
    }
}

@keyframes background-slide-out {
    0% {
        width: 100%
    }

    80% {
        width: -5px;
    }

    100% {
        width: 0
    }
}

main .common-section.two-columns {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue))
}

main .common-section.two-columns h2 {
    font-size: 40px;
    color: var(--sb-color-white);
    margin-bottom: 0.5em;
}

main .common-section.two-columns p {
    color: var(--sb-color-light-blue);
}
main .common-section.two-columns .sub-heading p {
    margin-bottom: 1.5em;
}

main .common-section.two-columns .column-wrapper {
    flex-wrap: wrap;
}

main .common-section.two-columns .column-wrapper .column {
    flex-basis: 45%
}
main .common-section.two-columns.three-columns .column-wrapper .column {
    flex-basis: 30%
}
main .common-section.two-columns.three-columns.column-text-white .column-wrapper .column p span {
    color: var(--sb-color-white) !important;
}

main .common-section.two-columns .column-wrapper .column h3 {
    color: var(--sb-color-white);
    font-size: 20px;
    margin-bottom: 20px
}

main .common-section.two-columns .column-wrapper .column .copy p {
    color: var(--sb-color-white);
    font-size: 16px;
    font-weight: 100;
}

main .common-section.two-columns .column-wrapper .sb-button-wrapper .sb-custom-button:hover:before {
    -webkit-transform: translate(83px, -50%);
    transform: translate(83px, -50%)
}

main .common-section.two-columns .column-wrapper .sb-button-wrapper .sb-custom-button {
    background: none;
    color: hsla(0, 0%, 100%, 0);
    font-size: 10px;
    font-weight: 700;
    transition: color .3s ease-in-out;
    transition-delay: .1s;
    vertical-align: middle
}

main .common-section.two-columns .column-wrapper .sb-button-wrapper .sb-custom-button:hover {
    color: var(--sb-color-white)
}

main .common-section.two-columns .column-wrapper .sb-button-wrapper .sb-custom-button:after {
    content: normal
}

main .common-section.two-columns .column-wrapper .sb-button-wrapper .sb-custom-button:before {
    right: auto
}

main .common-section.two-columns .column-wrapper .sb-button-wrapper .sb-custom-button span:before {
    content: normal
}

main .contact-boxes {
    margin-top: -3em;
    padding-top: 0;
    position: relative
}

main .contact-boxes .container {
    padding: 0
}

main .contact-boxes .info-box-wrapper .info-box {
    align-items: flex-start;
    background-color: var(--sb-color-light-grey);
    flex-basis: 25%;
    flex-direction: column;
    flex-grow: 1;
    min-height: 225px;
    padding: 30px;
    transition: all .4s ease-in-out;
    position: relative;
}

main .contact-boxes .info-box-wrapper .info-box:hover {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
}

@media screen and (min-width: 768px) {
    main .contact-boxes .info-box-wrapper .info-box:hover {
        margin-top: -1.5em;
    }
}

main .contact-boxes .info-box-wrapper .info-box:hover .text-wrapper .info-description,
main .contact-boxes .info-box-wrapper .info-box:hover .text-wrapper .info-description p,
main .contact-boxes .info-box-wrapper .info-box:hover .text-wrapper .infobox-heading {
    color: var(--sb-color-white)
}

main .contact-boxes.support .container {
    background-color: var(--sb-color-light-grey);
}

main .contact-boxes.support .info-box-wrapper {
    float: right;
    width: 30%;
}

main .contact-boxes.support .column-wrapper {
    width: 70%;
    float: left;
    background: var(--sb-color-light-grey);
}

main .contact-boxes.support .column-wrapper .column {
    align-items: center;
}

main .contact-boxes.support .solutions-wrapper-button {
    clear: both;
}

main .form-section .sb-button-wrapper {
    margin-top: 2em
}

main .the-goal {
    background: linear-gradient(78.66deg, var(--sb-color-dark-blue), var(--sb-color-light-blue))
}
main .the-goal .column .text-wrapper h3 {
    font-size: 20px;
}
main .our-team-section .heading h2 {
    margin-bottom: 1em;
}
main .our-team-section .info-box-wrapper {
    margin-bottom: 1em;
}
main .our-team-section .info-box-wrapper .info-box {
    flex-basis: 50%;
}
main .our-team-section .info-box-wrapper .info-box .text-wrapper {
    color: var(--sb-color-white);
    transition: all .3s linear;
    width: 100%;
    background: linear-gradient(271deg, transparent 0, rgba(0, 33, 62, .5) calc(0% + -1px), rgba(51, 101, 138, .6) calc(0% + 1px));
    padding: 2em 1em 0 1em;
    margin-top: -100px;
}
main .our-team-section .info-box-wrapper .info-box:last-child .text-wrapper {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 2.5em 1em;
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
}
main .our-team-section .info-box-wrapper .info-box:last-child .text-wrapper h3,
main .our-team-section .info-box-wrapper .info-box:last-child .text-wrapper p {
    font-size: 22px;
    letter-spacing: 2px;
}
main .our-team-section .info-box-wrapper .info-box .text-wrapper .infobox-heading {
    margin-bottom: 0;
}
main .our-team-section .info-box-wrapper .info-box .infobox-image {
    margin-bottom: 0;
    max-height: 340px;
    width: 100%;
    object-fit: cover;
}
main .our-team-section .sub-heading h3 {
    padding: 0.5em 0.5em;
    background: var(--sb-color-dark-blue);
    color: white;
    font-size: 1.2em;
    margin-bottom: 1em;
}


main .hosting-includes {
    background: var(--sb-color-light-grey);
}

main .hosting-includes .heading h2 {
    margin-bottom: 1em;
}

main .hosting-includes .info-box-wrapper .info-box {
    justify-content: center;
    align-items: flex-end;
}
main .hosting-includes .info-box-wrapper .info-box img {
    max-width: 88px;
}
main .hosting-includes .info-box-wrapper .info-box .text-wrapper {
    justify-content: center;
    display: flex;
}

main .hosting-includes .info-box-wrapper .info-box .text-wrapper h3 {
    max-width: 60%;
    text-align: center;
    font-size: 16px;
}

main .the-goal .column h2,
main .the-goal .column h3,
main .the-goal .column p {
    color: var(--sb-color-white)
}

main .the-goal .column p {
    margin-bottom: 20px
}

main .the-goal .column h2 {
    margin-bottom: 1em
}

.before-footer {
    background-color: var(--sb-color-white);
    padding: 4em 0
}

.before-footer.bg-grey {
    background-color: var(--sb-color-light-grey)
}

.before-footer h2 {
    font-size: 36px;
    margin-bottom: 20px
}

.before-footer p {
    color: var(--sb-color-light-blue)
}

footer {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    position: relative;
}
footer::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 59px;
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    z-index: 105;
    left: 0;
    bottom: 0;
}
footer .inner-footer .menu-wrapper {
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end
}
footer .more-info {
    cursor: pointer;
}
footer .inner-footer .menu-wrapper ul {
    margin-right: 0;
}

footer .inner-footer .menu-wrapper ul li a {
    color: var(--sb-color-white)
}
footer .inner-footer .bottom-layout-wrapper {
    position: relative;
    z-index: 999;
}
footer .inner-footer .bottom-layout {
    align-items: center;
    border-top: 1px solid var(--sb-color-white);
    border-color: var(--sb-color-white);
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
}

footer .inner-footer .bottom-layout .bottom-layout-col {
    color: var(--sb-color-white);
    font-size: 10px;
    letter-spacing: 2px;
    text-transform: uppercase
}

footer .inner-footer .bottom-layout .socials {
    display: flex;
    justify-content: space-around;
}

footer .inner-footer .bottom-layout .socials li a:after {
    content: unset !important;
}

footer .inner-footer .bottom-layout .socials li img {
    display: inline;
    max-width: 100%;
}

footer .inner-footer .bottom-layout .bottom-layout-col.width-15 {
    flex-basis: 15%
}
footer .more-info-container {
    background: linear-gradient(78.66deg, #00213E, #33658A);
    position: absolute;
    max-height: 0;
    transition: all .5s ease-out;
    overflow: hidden;
    bottom: 0;
    left: 0;
    z-index: 101;
    color: #fff;
    font-size: 13px;
    width: 100%;
    border-top: 1px solid var(--sb-color-white);
    border-bottom: 1px solid var(--sb-color-white); 
}
footer .more-info-container.visible { 
    max-height: 650px;
    padding: 3em 0;
    bottom: 55px;
}
footer .more-info-container .more-info-columns .column {
    flex-basis: 50%;
    color: var(--sb-color-white);
    display: flex;
    flex-wrap: wrap;
}
footer .more-info-container .more-info-columns .column .footer-menu {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 45%;
}
footer .more-info-container .more-info-columns .column .footer-menu li {
    flex-basis: 100%;
}
footer .more-info-container .more-info-columns .column .footer-menu li.sub-menu a {
    padding-left: 0.5em;
}
footer .more-info-container .more-info-columns .column .footer-menu li.sub-menu a::before {
    content: "\2022";
    margin-right: 1em;
}
footer .more-info-container .more-info-columns .column .footer-about-copy {
    font-size: 16px;
    color: var(--sb-color-white);
}
footer .more-info-container .more-info-columns .column h4 {
    font-size: 30px;
    flex-basis: 100%;
}

/* RESPONSIVE STYLES */

/* Small screens */
@media screen and (max-width: 767px) {

    #root p,
    #root p > * {
        font-size: 14px;
        line-height: 28px;
    }

    #root h2 {
        font-size: 22px;
    }

    #xxxroot main section {
        padding: 2em 1em;
    }
    #root main .container:not(.sb-hero-inner-container) {
        padding-left: 20px;
        padding-right: 20px;
    }

    #root header .menu-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
        height: 0;
        width: 100%;
        top: 0;
        left: 0;
        transition: all 0.8s cubic-bezier(.15, .7, 0, 1);
        overflow: hidden;
    }

    #root header nav .mobile-menu button {
        position: relative;
        z-index: 90;
        color: var(--sb-color-white);
    }

    #xxxroot header nav .mobile-menu button.open svg {
        display: none;
    }

    #root header .menu-wrapper .mobile-logo-header {
        display: block;
    }

    #root header .menu-wrapper.display-menu {
        height: 100vh;
    }

    #root header .menu-wrapper .menu {
        flex-wrap: wrap;
        justify-content: center;
    }

    #root header .menu-wrapper .menu li {
        flex-basis: 100%;
        text-align: center;
        padding: 1.5em 0;
    }

    #root header .menu-wrapper .socials {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1em 2em;
    }

    #root header nav .container .logo-header {
        width: 200px;
    }

    #root header nav .container .mobile-menu button svg {
        fill: var(--sb-color-white);
    }
    #root header nav .container .mobile-menu button svg .line {
        opacity: 1;
        transform: rotate(0) translateY(0) translateX(0);
        transform-origin: 1em 1em;
        transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
    }
    #root header nav .container .mobile-menu button svg .line-1 {
        transform-origin: 1em 2.5em;
    }
    #root header nav .container .mobile-menu button svg .line-3 {
        transform-origin: 1em 4em;
    }
    #root header nav .container .mobile-menu button.open svg .line-1 {
        transform: rotate(45deg) translateY(0) translateX(0);
    }
    #root header nav .container .mobile-menu button.open svg .line-2{
        opacity: 0;
    }
    #root header nav .container .mobile-menu button.open svg .line-3 {
        transform: rotate(-45deg) translateY(0em) translateX(0em);
    }
    .sb-hero.web-app-design .description-wrapper .description-row h4 {
        font-size: 20px;
    }
    main .sb-button-wrapper .sb-custom-button, 
    main .sb-button-wrapper .submit-button {
        font-size: 14px;
    }
    main .sb-button-wrapper .sb-custom-button:after, 
    main .sb-button-wrapper .submit-button:after {
        background-size: cover;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
        right: 10px;
    }
    main .sb-button-wrapper .sb-custom-button:before, 
    main .sb-button-wrapper .submit-button:before {
        width: 32px;
        right: 27px;
    }
    main .sb-custom-button.more-info span {
        color: var(--sb-color-white) !important;
    }
    main .sb-custom-button.more-info::before {
        transform: translate(83px, -30%);
        width: 4px;
    }
   
    #root main {
        margin-top: 0
    }

    main .hosting-includes .info-box-wrapper .info-box {
        justify-content: flex-start;
    }
   
    main .common-section.pro-tips .column-wrapper {
        flex-wrap: wrap;
    }
    main .common-section.pro-tips .column-wrapper .column::before {
        font-size: 85px;
        line-height: 85px;
        left: 0 !important;
    }

    #root .sb-hero .sb-columns {
        flex-wrap: wrap;
    }
    #root .sb-hero .sb-columns .sb-column {
        flex-basis: 100%;
    }
    #root .sb-hero.sb-hero.hero-about{
        min-height: 265px !important;
    }

    #root .sb-hero .sb-hero-inner-container h1 {
        font-size: 20px;
        max-width: 100%
    }

    main .sb-hero.maintenance,
    main .sb-hero.integrations {
         min-height: 300px !important;   
    }
    main .sb-hero.maintenance .sb-columns .sb-column:nth-child(1),
    main .sb-hero.integrations .sb-columns .sb-column:nth-child(1) {
         flex-basis: 100% !important;   
    }
    main .sb-hero.maintenance .sb-columns .sb-column:nth-child(2),
    main .sb-hero.integrations .sb-columns .sb-column:nth-child(2) {
         display: none;   
    }
    .sb-hero .sb-columns:not(.animated) .sb-column:nth-child(2) img {
        top: 100% !important;
        left: 0;
        max-width: 100% !important;
    }

    #root .sb-hero.web-app-design {
        align-items: flex-start;
    }

    #root .sb-hero.hero-animated .design-icons .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    #root main .initial-section h2 {
        font-size: 22px
    }
    #root main .initial-section h3 {
        font-size: 18px
    }

    #root .solutions-wrapper-button {
        flex-wrap: wrap;
        gap: 0;
    }

    #root .solutions-wrapper-button .sb-button-wrapper {
        display: flex;
        flex-basis: 100%;
        padding: 0.3em 0;
    }

    #root main .masonry-grid {
        grid-gap: 10px 0;
        grid-template-columns: 1fr;
        grid-template-rows: 190px;
    }
    #root main .masonry-grid-section .masonry-grid .masonry-column {
        grid-row: span 2 ;
    }
    #root main .masonry-grid .masonry-column:first-child,
    #root main .masonry-grid .masonry-column:last-child {
        grid-row: span 1;
    }

    #root .info-box-wrapper {
        flex-wrap: wrap;
    }

    main .contact-boxes {
        margin: 0;
        padding-bottom: 0;
    }

    main .contact-boxes .container {
        padding: 0 !important;
    }
    main .contact-boxes .info-box-wrapper .info-box {
        margin-top: 0;
        min-height: 50px;
        flex-basis: 45%;
    }
    main .form-section .form-feedback {
        margin-bottom: 0;
    }
    main .contact-boxes .info-box-wrapper .info-box .text-wrapper {
        flex-basis: unset;
    }
    main .contact-boxes .info-box-wrapper .info-box .text-wrapper h3 {
        font-size: 16px;
    }
    main .contact-boxes .info-box-wrapper .info-box .text-wrapper .info-description {
        font-size: 14px;
    }

    main .contact-boxes .info-box-wrapper .info-box .infobox-image {
        margin-right: 2em;
    }

    .form-section .form .input-container {
        flex-basis: 100% !important;
    }
    .support-section .info-box-wrapper {
        gap: 1em 0;
    }
    .support-section .info-box-wrapper .info-box img {
        max-width: 55px;
        max-height: 48px;
    }

    .support-section .info-box-wrapper .info-box {
        justify-content: flex-start;
        text-align: left;
        flex-basis: 50%;
    }

    .support-section .info-box-wrapper .info-box h3 {
        font-size: 12px;
    }
    main .common-section.maintenance-page .info-box-wrapper {
        gap: 5em 1.5em;
    }
    main .common-section.maintenance-page .info-box {
        margin-bottom: -10px;
        margin-top: -50px;
        padding-top: 30px
    }
    
    main .common-section.maintenance-page .info-box::before {
        opacity: 0;
        top: 50%
    }
    main .common-section.maintenance-page .info-box::after {
        opacity: 1
    }
    main .common-section.maintenance-page .info-box .text-wrapper .info-description p,
    main .common-section.maintenance-page .info-box .text-wrapper .info-description,
    main .common-section.maintenance-page .info-box .text-wrapper h3 {
        color: var(--sb-color-white)
    }
    main .common-section.maintenance-page .info-box .text-wrapper .info-description {
        opacity: 1;
        font-size: 14px;
        line-height: inherit;
    }
    main .our-team-section .info-box-wrapper .info-box .text-wrapper .infobox-heading {
        font-size: 14px;
    }
    main .our-team-section .info-box-wrapper .info-box .text-wrapper .info-description {
        font-size: 12px;
    }
    main .our-team-section .info-box-wrapper .info-box .text-wrapper {
        padding-top: 1em;
        margin-top: -80px;
    }
    main .our-team-section .info-box-wrapper .info-box:last-child .text-wrapper {
        padding: 1.2em 1em;
    }
    main .common-section.choose-us .column-wrapper {
        flex-wrap: wrap;
    }
    main .common-section.choose-us .column-wrapper .column,
    main .common-section.two-columns.three-columns .column-wrapper .column {
        flex-basis: 100%
    }

    footer {
        padding: 0 20px
    }
    footer::after {
        height: 90px;
    }
    footer .inner-footer .bottom-layout {
        flex-wrap: wrap;
        justify-content: center;
        align-items: start;
    }
    footer .inner-footer .bottom-layout-wrapper {
        z-index: unset;
    }
    footer .inner-footer .bottom-layout .bottom-layout-col:first-child {
        order: 2;
        flex-basis: 65% !important;
        z-index: 120;
    }
    footer .inner-footer .bottom-layout .bottom-layout-col:last-child {
        order: 3;
        flex-basis: 35% !important;
        text-align: right;
        z-index: 120;
    }
    footer .inner-footer .bottom-layout .bottom-layout-col {
        font-size: 10px;
        margin-bottom: 20px;
        flex-basis: 100% !important;
    }
    footer .more-info-container.visible {
        max-height: 850px;
        padding: 1em 0;
        bottom: 90px;
    }
    footer .more-info-container .more-info-columns {
        flex-wrap: wrap;
    }
    footer .more-info-container .more-info-columns .column {
        flex-basis: 100%;
    }
    footer .more-info-container .more-info-columns .column:first-child   {
        display: none;
    }
    footer .more-info-container .more-info-columns .column:nth-child(2) {
        padding: 1em 0;
    }
    footer .more-info-container .more-info-columns .column:nth-child(2) h4 {
        font-size: 25px;
    }
    footer .more-info-container .more-info-columns .column:nth-child(2) p {
        font-size: 12px;
    }

    #root footer .inner-footer {
        padding: 0;
    }
    #root footer .inner-footer .top-layout {
        flex-direction: column;
    }
    footer .inner-footer .menu-wrapper {
        justify-content: center
    }
    footer .inner-footer .menu-wrapper .menu {
        flex-wrap: wrap;
        margin-top: 1em;
    }
    footer .inner-footer .menu-wrapper .menu li {
        flex-basis: 45%;
    }
    #root footer ul li a {
        font-weight: 400;
    }

    .hide-block {
        display: none
    }

    main .slider {
        padding: 0;
        background: none;
    }
    main .slider .container {
        padding: 0 !important;
    }
    .slider:not(.jobs) .inner-slider h2 {
        display: none;
    }
    .slider:not(.jobs) .inner-slider .left-col {
        flex-basis: 100%;
        order: 1;
    }
    .slider .inner-slider .slider-copy {
        padding:0 1.5em 1.5em 1.5em;
    }

    .slider .inner-slider .left-col .slider-track .slide,
    .slider .inner-slider .left-col .slider-track .slide img {
        width: 100%;
    }
    .slider .inner-slider .column-wrapper {
        flex-wrap: wrap;
    }

    main .slider .inner-slider .become-part {
        justify-content: space-between;
        flex-direction: unset;
        flex-basis: 100%;
        border: none;
        align-items: center;
    }
    main .slider .inner-slider .become-part h3 {
        font-size: 18px;
        margin-bottom: 0;
        flex-basis: 65%;
    }
    main .slider:not(.jobs) .inner-slider .slider-track {
        padding: 0;
    }
    .slider .inner-slider .left-col .slider-track .slide:hover {
        transform: scale(1);
    }

    .slider .inner-slider .left-col .slider-track .slide {
        flex-basis: 50%;
    }
    main .slider .inner-slider .slide .profile-content {
        opacity: 1;
        height: 35%;
        bottom: 0;
    }
    main .slider .inner-slider .slide .profile-content .team-member-name {
        font-size: 14px;
    }
    main .slider .inner-slider .slide .profile-content .team-member-position {
        font-size: 12px;
    }
    

    main .common-section.two-columns .column-wrapper .column {
        flex-basis: 100%;
    }

    main .solutions-section .info-box {
        flex-basis: 100% !important;
        flex-wrap: wrap;
    }

    main .solutions-section .info-box .infobox-heading {
        max-width: 100%;
    }
    
    main .solutions-section .info-box img {
        max-width: 75px !important;
    }

    main .solutions-section .column-wrapper {
        flex-wrap: wrap;
    }

    main .solutions-section .info-box .text-wrapper {
        margin-left: 0;
        margin-top: 1em;
    }

    main .solutions-section .sb-button-wrapper {
        justify-content: flex-start !important;
    }

    main .ourmission-section {
        background: linear-gradient(to bottom, var(--sb-color-light-blue), #7fc6a4 25%, var(--sb-color-black-pearl) 0, var(--sb-color-dark-blue) 0);
    }
    main .ourmission-section .container {
        padding: 0 !important;
    }
    main .ourmission-section .columns {
        flex-wrap: wrap;
    }
    main .ourmission-section .ourmission-container .leftcol,
    main .ourmission-section .ourmission-container .rightcol {
        flex-basis: 100%;
        transform: unset;
    }
    main .ourmission-section .ourmission-container .leftcol {
        xxxmargin-bottom: 4.1em;
        padding: 0 2em;
    }
    main .ourmission-section .ourmission-container .rightcol { 
        margin-left: calc( -50vw + 50%);
        margin-right: calc( -50vw + 50%) !important;
        max-width: 100vw;
        margin-top: -10px;
        padding: 2em;
    }
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper {
        transform: translateY(-15%);
        top: 70px;
        height: 100%;
        width: 100%;
        position: relative;
    }
    main .ourmission-section .ourmission-container .rightcol .inner-col {
        transform: unset;
    }
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo {
        xleft: 30px;
        xmax-width: 85%;
    }
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo.middle {
        left: 15%;
        top: 58px;
        max-width: 70%;
    }
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo.inner {
        left: 30%;
        top: 112px;
        max-width: 40%;
    }
    main .ourmission-section .ourmission-container .leftcol h2 {
        top: 60px;
    }
    main .ourmission-section .ourmission-container .leftcol h2 br {
        display: none;
    }

    main .ourmission-section .ourmission-container .rightcol .counters {
        flex-wrap: wrap;
    }

    main .ourmission-section .ourmission-container .rightcol .counters .counter {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        flex-basis: 45%;
    }

    main .ourmission-section .ourmission-container .rightcol .counters span {
        font-size: 36px;
    }

    main .ourmission-section .ourmission-container .rightcol .inner-col p {
        margin-bottom: 1em;
    }


    main .ourmission-section .ourmission-container .rightcol .counters p {
        font-size: 16px;
    }

    main .team-quality-section {
        background: var(--sb-color-dark-blue);
    }

    main .team-quality-section .columns {
        flex-wrap: wrap;
    }

    main .team-quality-section .columns .column {
        flex-basis: 100%;
        flex-wrap: wrap;
        padding-right: 0;
        padding-left: 0 !important;
    }

    main .team-quality-section .columns .column .info-box-wrapper .info-box {
        flex-basis: 35%;
    }

    main .team-quality-section .columns .column .info-box-wrapper .info-box .infobox-heading {
        font-size: 15px;
    }

    main .team-quality-section .columns .column .info-box-wrapper .info-box .infobox-image {
        max-width: 70%;
    }

    main .team-quality-section .columns .column .team-quality-copy p {
        color: var(--sb-color-white);
    }

    main .team-quality-section .columns .column h2 {
        font-size: 25px;
        margin-bottom: 2em;
    }


    .sb-hero.web-app-design .description-wrapper {
        max-width: 100%;
    }

    main .mobile-growth .column-wrapper,
    #root main .the-goal .column-wrapper,
    main .need-custom-app .container .column-wrapper {
        flex-wrap: wrap;
    }

    #root main .the-goal .column-wrapper .column:nth-child(2) {
        display: none;
    }

    #root main .mobile-growth .column-wrapper .column .column-heading::before {
        font-size: 42px;
        line-height: 50px;
    }

    #root main .mobile-growth .column-wrapper .column .column-heading {
        font-size: 15px;
    }

    #root main .wearable-apps {
        padding: 2em 0;
    }

    #root main .wearable-apps .column-wrapper .column {
        flex-basis: auto;
    }

    #root main .wearable-apps .column-wrapper .column .copy p:not(:first-child) {
        display: none;
    }

    main .need-custom-app .container .heading,
    #root main .need-custom-app {
        margin-top: 0 !important;
    }

    main .need-custom-app .container .column-wrapper {
        display: none;
    }

    #root main .need-custom-app .container .column-wrapper .column {
        flex-basis: auto;
    }

    main .tab-services .tab-services-wrapper {
        flex-wrap: wrap;
        padding-top: 2em;
    }

    main .tab-services .tab-services-wrapper .tabs,
    main .tab-services .tab-services-wrapper .panels {
        flex-basis: 100%;
        background: none;
        gap: 2em 0;
    }

    main .tab-services .tab-services-wrapper .panels:not(.for-mobile-only) {
        display: none;
    }

    main .tab-services .tab-services-wrapper .tabs .tab:nth-child(1) {
        order: 0
    }

    main .tab-services .tab-services-wrapper .tabs .tab:nth-child(2) {
        order: 2
    }

    main .tab-services .tab-services-wrapper .tabs .tab:nth-child(3) {
        order: 4
    }

    .tab-services .tab-services-wrapper .tabs .tab.active {
        display: none;
    }

    main .tab-services:not(.websites) .tab-services-wrapper .panels .panel .list-wrapper .list ul li p {
        line-height: 50px !important;
    }

    main .tab-services .tab-services-wrapper .panels .panel .list-wrapper .list ul li p::before {
        height: 30px;
        left: 0;
        position: absolute;
        top: 0;
        width: 31px;
        background-position: left;
        background-size: contain;
        background-repeat: no-repeat;
    }

    main .tab-services .tab-services-wrapper .panels .panel {
        background-image: none !important;
        background: linear-gradient(78.66deg, var(--sb-color-dark-blue), var(--sb-color-light-blue)) !important;
    }

    main .tab-services .tab-services-wrapper .panels .panel .description-wrapper {
        flex-wrap: wrap;
        max-width: 100%;
    }

    main .tab-services .tab-services-wrapper .panels .panel .icons {
        max-width: 100%;
        gap: 0 1em;
    }

    main .tab-services .tab-services-wrapper .panels .panel {
        padding: 0 1.5em;
        height: 0;
        display: block;
        transition: opacity 0.8s ease, transform 0.8s ease;
        opacity: 0;
        transform: scale(0.1);
        transform-origin: top left;
    }
    main .tab-services .tab-services-wrapper .panels .panel.active {
        animation: unset;
        height: 100%;
        opacity: 1;
        transform: scale(1);
    }

    main .tab-services .tab-services-wrapper .panels .panel .panel-title-container {
        max-width: 100%;
    }


    main .tab-services .tab-services-wrapper .tabs .tab h3 {
        font-size: 16px;
    }

    .tab-services .tab-services-wrapper .tabs .tab.active .tab-icon-container {
        display: none;
    }

    main .tab-services .tab-services-wrapper.mobile-apps .panels .panel {
        background-image: none;
    }

    main .clients-section .info-box-wrapper {
        gap: 1em;
    }
    main .clients-section.clients-bottom .info-box-wrapper {
        margin-top: 3em;
    }
    main .clients-section .info-box-wrapper .info-box {
        flex-basis: 45%;
        justify-content: center;
    }
    main .clients-section.clients-bottom {
        margin-top: -80px;
    }
    main .clients-section .info-box-wrapper .info-box img {
        max-width: 80%;
    }
    .clients-section.clients-bottom .info-box-wrapper .info-box:first-child {
        display: block;
    }
    .before-footer h2 {
        font-size: 30px;
    }
    main .hosting-includes .info-box-wrapper .info-box {
        flex-basis: 45%;
    }
    main .hosting-includes .info-box-wrapper .info-box .text-wrapper h3 {
        max-width: 100% !important;
        font-size: 12px;
        text-align: left;
    }
    main .common-section.web-hosting .column-wrapper .column {
        flex-basis: 100%;
    }
    main .common-section.web-hosting .column-wrapper .column::before {
        animation-duration: .5s;
        animation-fill-mode: forwards;
        animation-name: background-slide-in;
        animation-timing-function: ease-out;
    }
    main .common-section.web-hosting .column-wrapper .column .column-heading, 
    main .common-section.web-hosting .column-wrapper .column .copy p {
        color: var(--sb-color-white);
    }
    main .common-section.web-hosting .column-wrapper .column .copy p code {
        -webkit-text-fill-color: transparent;
        background: -webkit-linear-gradient(#7fc6a4, var(--sb-color-light-blue));
        -webkit-background-clip: text;
        font-family: capitana;
    }
    main .common-section.web-hosting .column-wrapper .column .sb-button-wrapper {
        opacity: 1;
        margin-top: -55px;
    }
    main .common-section.web-hosting .column-wrapper .column .sb-button-wrapper a::after {
        right: 0;
    }
    main .hosting-includes .info-box-wrapper .info-box .infobox-image {
        max-width: 40%;
    }
    .xxtab-services .tab-services-wrapper .panels .panel.Development .icons .icon-wrapper:last-child img {
        max-width: 30px;
    }

}

/*================================================================================================================================*/
/*===================================               STYLES FOR TABLETS                                  ==========================*/
/*================================================================================================================================*/


/* @media screen and (min-width: 769px) and (max-width: 1075px),
 (min-width: 769px) and (max-width: 1440px) and (orientation: landscape) { */

@media screen and (min-width: 769px) and (max-width: 1280px),
(min-width: 769px) and (max-width: 1280px) and (orientation: landscape) {
    #root main section {
        padding: 2em;
    }
    #root main section.mobile-carousel {
        padding: 0;
    }
    #root .menu-wrapper .menu {
        column-gap: 1em;
    }
    main .sb-hero.maintenance,
    main .sb-hero.integrations {
         min-height: 300px !important;   
    }
    main .sb-hero.maintenance .sb-columns .sb-column:nth-child(1),
    main .sb-hero.integrations .sb-columns .sb-column:nth-child(1) {
         flex-basis: 100% !important;   
    }
    main .sb-hero.maintenance .sb-columns .sb-column:nth-child(2),
    main .sb-hero.integrations .sb-columns .sb-column:nth-child(2) {
         display: none;   
    }

    main .column-wrapper,
    main .info-box-wrapper {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    main .clients-section .info-box-wrapper .info-box {
        flex-basis: 50%;
        xxjustify-content: center;
    }
    main .tab-services .tab-services-wrapper .panels:not(.for-mobile-only) {
        display: none;
    }
    #root main section h2 {
        font-size: 36px !important;
    }
    main .need-custom-app .container .column-wrapper .column,
    main .solutions-section .info-box {
        flex-basis: 100% !important;
    }
    main .wearable-apps .column-wrapper{
        align-items: center;
    }
    main .wearable-apps .column-wrapper .column:nth-child(1) {
        flex-basis: 50% !important;
    }
    main .need-custom-app {
        margin-top: 0 !important;
    }
    main .need-custom-app .container .heading {
        margin-top: 0 !important;
    }
    main .need-custom-app .container .column-wrapper,
    #root main .wearable-apps .column-wrapper .column .copy p:not(:first-child) {
        display: none;
    }
    .sb-hero.web-app-design .animated .design-icons {
        max-width: unset;
    }
    main .tab-services .tab-services-wrapper .tabs {
        flex-basis: 100%;
        gap: 1.5em 0;
    }
    main .tab-services .tab-services-wrapper .tabs .tab:nth-child(1) {
        order: 0
    }
    main .tab-services .tab-services-wrapper .tabs .tab:nth-child(2) {
        order: 2
    }

    main .tab-services .tab-services-wrapper .tabs .tab:nth-child(3) {
        order: 4
    }

    .tab-services .tab-services-wrapper .tabs .tab.active {
        display: none;
    }

    main .tab-services:not(.websites) .tab-services-wrapper .panels .panel .list-wrapper .list ul li p {
        line-height: 50px !important;
    }

    main .tab-services .tab-services-wrapper .panels .panel .list-wrapper .list ul li p::before {
        height: 30px;
        left: 0;
        position: absolute;
        top: 0;
        width: 31px;
        background-position: left;
        background-size: contain;
        background-repeat: no-repeat;
    }

    main .tab-services .tab-services-wrapper .panels .panel {
        background-image: none !important;
        background: linear-gradient(78.66deg, var(--sb-color-dark-blue), var(--sb-color-light-blue)) !important;
    }

    main .tab-services .tab-services-wrapper .panels .panel .description-wrapper {
        flex-wrap: wrap;
        max-width: 100%;
    }

    main .tab-services .tab-services-wrapper .panels .panel .icons {
        max-width: 100%;
    }
    main .tab-services .tab-services-wrapper .panels {
        background: none;
    }
    main .tab-services .tab-services-wrapper .panels .panel {
        padding: 0 1.5em;
        height: 0;
        display: block;
        transition: opacity 0.8s ease, transform 0.8s ease;
        opacity: 0;
        transform: scale(0.1);
        transform-origin: top left;
    }
    main .tab-services .tab-services-wrapper .panels .panel.active {
        animation: unset;
        height: 100%;
        opacity: 1;
        transform: scale(1);
    }

    main .tab-services .tab-services-wrapper .panels .panel .panel-title-container {
        max-width: 100%;
    }


    main .tab-services .tab-services-wrapper .tabs .tab h3 {
        font-size: 16px;
    }

    .tab-services .tab-services-wrapper .tabs .tab.active .tab-icon-container {
        display: none;
    }

    main .tab-services .tab-services-wrapper.mobile-apps .panels .panel {
        background-image: none;
    }
    #root main .masonry-grid .masonry-column.mws .second-image {
        max-width: 50%;
    }
    main .team-quality-section {
        background: linear-gradient(90deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue) 100%, #7fc6a4 0, var(--sb-color-light-blue) 89%);
    }
    main .team-quality-section .columns {
        flex-wrap: wrap;
    }
    main .team-quality-section .columns .column {
        flex-basis: 100%;
    }
    main .team-quality-section .columns .column:nth-child(2) {
        padding-left: 0;
        margin-top: 2em;
    }
    main .team-quality-section .columns .column .team-quality-copy p {
        color: var(--sb-color-white);
    }
    main .common-section.maintenance-page .info-box-wrapper {
        gap: 5em 1.5em;
    }
    main .common-section.maintenance-page .info-box {
        margin-bottom: -10px;
        margin-top: -50px;
        padding-top: 30px
    }
    
    main .common-section.maintenance-page .info-box::before {
        opacity: 0;
        top: 50%
    }
    main .common-section.maintenance-page .info-box::after {
        opacity: 1
    }
    main .common-section.maintenance-page .info-box .text-wrapper .info-description p,
    main .common-section.maintenance-page .info-box .text-wrapper .info-description,
    main .common-section.maintenance-page .info-box .text-wrapper h3 {
        color: var(--sb-color-white)
    }
    main .common-section.maintenance-page .info-box .text-wrapper .info-description {
        opacity: 1;
        font-size: 14px;
    }
    main .sb-hero.hero-about {
        min-height: 490px !important;
        padding-bottom: 0;
    }
    main .slider {
        padding: 5em 0;
    }
    main .slider .inner-slider .become-part {
        justify-content: space-between;
        flex-direction: unset;
        flex-basis: 100%;
        border: none;
        align-items: center;
    }
    .slider:not(.jobs) .inner-slider .left-col {
        flex-basis: 100%;
        order: 1;
    }
    main .slider .inner-slider .become-part h3 {
        flex-basis: 75%;
    }
    main .slider .inner-slider .slide {
        flex-basis: 20%;
    }
    main .slider .inner-slider .arrow.left-arrow {
        left: 35%;
    }
    main .slider .inner-slider .arrow {
        right: 35%;
    }
    main .slider .inner-slider .slide .profile-content {
        padding: 25px 10px 0;
    }
    main .slider .inner-slider .slide .profile-content .team-member-name {
        font-size: 12px;
    }
    main .slider .inner-slider .slide .profile-content .team-member-position {
        font-size: 10px;
    }
    main .ourmission-section {
        background: linear-gradient(to bottom, var(--sb-color-light-blue), #7fc6a4 25%, var(--sb-color-black-pearl) 0, var(--sb-color-dark-blue) 0);
    }
    main .ourmission-section .columns {
        flex-wrap: wrap;
    }
    main .ourmission-section .ourmission-container .leftcol, main .ourmission-section .ourmission-container .rightcol {
        flex-basis: 100%;
        transform: unset;
    }
    main .ourmission-section .ourmission-container .leftcol {
        margin-bottom: 8.5em;
    }
    main .ourmission-section .container {
        padding: 0 !important;
    }
    main .ourmission-section .ourmission-container .rightcol {
        xxxmargin-left: 0;
        xxxpadding: 1em 0;
        margin-left: calc( -50vw + 50%);
        margin-right: calc( -50vw + 50%) !important;
        max-width: 100vw;
        margin-top: -57px;
        padding: 2em;
    }
    main .ourmission-section {
        background: linear-gradient(to bottom, var(--sb-color-light-blue), #7fc6a4 30%, var(--sb-color-black-pearl) 0, var(--sb-color-dark-blue) 0);
    }
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper {
        transform: translateY(-15%) translateX(-50%);
        top: 60px;
        width: 50%;
        left: 50%;
    }
    main .ourmission-section .ourmission-container .leftcol h2 {
        top: 0;
    }
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo {
        width: 100%;
    }
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo.middle {
        left: 40px;
        top: 48px;
        width: 80%;
    }
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo.inner {
        left: 75px;
        top: 98px;
        width: 60%;
    }
    main .ourmission-section .ourmission-container .rightcol .inner-col {
        transform: unset;
    }
    main .ourmission-section .ourmission-container .rightcol .inner-col .counters {
        flex-wrap: wrap;
    }
    main .ourmission-section .ourmission-container .rightcol .inner-col .counters .counter {
        flex-basis: 50%;
    }
    .clients-section.clients-bottom .info-box-wrapper .info-box:first-child {
        display: block;
    }
    main .clients-section .info-box-wrapper {
        margin-top: 3em;
        gap: 2em 0;
    }
    main .clients-section.clients-bottom .info-box-wrapper{
        margin-top: -42px;
    }
    
    main .form-section .form .input-container,
    main .contact-boxes .info-box-wrapper .info-box {
        flex-basis: 45%;
    }
    main .common-section.web-hosting .column-wrapper .column {
        flex-basis: 100%;
    }
    main .common-section.web-hosting .column-wrapper .column .column-heading, main .common-section.web-hosting .column-wrapper .column .copy p {
        color: var(--sb-color-white);
    }
    main .common-section.web-hosting .column-wrapper .column .sb-button-wrapper {
        opacity: 1;
    }
    main .common-section.web-hosting .column-wrapper .column::before {
        animation-duration: .5s;
        animation-fill-mode: forwards;
        animation-name: background-slide-in;
        animation-timing-function: ease-out;
    }
    main .hosting-includes .info-box-wrapper {
        gap: 2em 0;
    }
    main .hosting-includes .info-box-wrapper .info-box {
        flex-basis: 50%;
        justify-content: flex-start !important;
        align-items: center;
    }
    main .hosting-includes .info-box-wrapper .info-box .infobox-image {
        max-width: 25%;
    }
    main .common-section.two-columns .column-wrapper .column {
        flex-basis: auto;
    }
    main .hosting-includes .info-box-wrapper .info-box .text-wrapper {
        flex-basis: 50%;
    }
    main .hosting-includes .info-box-wrapper .info-box .text-wrapper h3 {
        text-align: left !important;
    }
    main .common-section.web-hosting .column-wrapper .column .text-wrapper {
        width: 100%;
    }
    main .common-section.web-hosting .column-wrapper .column .copy p code {
        -webkit-text-fill-color: transparent;
        background: -webkit-linear-gradient(#7fc6a4, var(--sb-color-light-blue));
        -webkit-background-clip: text;
        font-family: capitana;
        font-size: 20px;
    }
   
    
    main .support-section .info-box-wrapper .info-box {
        text-align: left;
        justify-content: flex-start;
        flex-basis: 30%;
    }
    main .contact-boxes {
        margin-top: 0;
    }
    main .common-section.pro-tips .column-wrapper {
        flex-wrap: wrap;
    }
    main .common-section.pro-tips .column-wrapper .column::before {
        font-size: 85px;
        line-height: 85px;
        left: 0 !important;
    }
    main .mobile-growth .column-wrapper .column .column-heading {
        padding-left: 4em !important;
    }
    main .mobile-growth .column-wrapper .column .column-heading::before {
        font-size: 40px !important;
        line-height: 40px !important;
    }
    main .mobile-growth .column-wrapper .column .column-heading::after {
        left: 2.8em !important; 
    }

    footer::after {
        height: 94px;
    }

    footer .inner-footer .top-layout {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1em 0;
    }
    footer .inner-footer .menu-wrapper {
        justify-content: center;
    }
    footer .more-info-container .more-info-columns .column {
        display: block;
    }
    footer .more-info-container .more-info-columns .column .footer-about-copy {
        font-size: 14px;
        line-height: 30px;
    }
    footer .inner-footer .bottom-layout {
        flex-wrap: wrap;
        gap: 1em 0;
    }
    footer .inner-footer .bottom-layout .bottom-layout-col {
        flex-basis: 45%;
    }
    footer .inner-footer .bottom-layout .bottom-layout-col:nth-child(1) {
        order: 2;
        flex-basis: 75%;
    }
    footer .inner-footer .bottom-layout .bottom-layout-col:nth-child(2) {
        order: 0;
    }
    footer .inner-footer .bottom-layout .bottom-layout-col:nth-child(3) {
        order: 1;
        flex-basis: 45%;
    }
    footer .inner-footer .bottom-layout .bottom-layout-col:nth-child(4) {
        order: 3;
        flex-basis: 25%;
        text-align: right;
    }
    footer .more-info-container.visible {
        bottom: 66px;
    }

}


/*================================================================================================================================*/
/*===================================               STYLES FOR TABLETS  LANDSCAPE                       ==========================*/
/*================================================================================================================================*/


/* @media only screen and (min-width :800px) and (max-width : 1024px) and (orientation : landscape) {

    #root .menu-wrapper .menu li a {
        color: rgb(17, 255, 0);
    }
} */


@media only screen and (min-device-width : 768px) and (max-device-width : 1280px) and (orientation : landscape) {
    
    main .common-section.pro-tips .column-wrapper .column {
        min-height: 80px;
    }

    main .the-goal .column-wrapper,
    main .the-search-is-over .column-wrapper,
    main .solutions-section .info-box-wrapper {
        flex-wrap: nowrap;
    }
    main .the-goal .column-wrapper .column {
        flex-basis: 50%;
    }
    main .common-section.two-columns .column-wrapper .column {
        flex-basis: 45%;
    }
    
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo.middle {
        left: 70px;
        top: 70px;
        width: 70%;
    }
    main .ourmission-section .ourmission-container .leftcol .logo-wrapper .spinning-logo.inner {
        left: 128px;
        top: 128px;
        width: 45%;
    }
    main .our-team-section .info-box-wrapper .info-box {
        flex-basis: 33.33%;
    }
}


.select2-container {
    min-width: 400px
}

.select2-results__option {
    padding-right: 20px;
    vertical-align: middle
}

.select2-results__option:before {
    background-color: var(--sb-color-white);
    border: 2px solid var(--sb-color-light-grey);
    border-radius: 4px;
    content: "";
    display: inline-block;
    height: 20px;
    margin-right: 20px;
    position: relative;
    vertical-align: middle;
    width: 20px
}

.select2-results__option[aria-selected=true]:before {
    background-color: #f77750;
    border: 0;
    color: var(--sb-color-white);
    content: "f";
    display: inline-block;
    font-family: fontAwesome;
    padding-left: 3px
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: var(--sb-color-white)
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #eaeaeb;
    color: #272727
}

.select2-container--default .select2-selection--multiple {
    margin-bottom: 10px
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-radius: 4px
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #f77750;
    border-width: 2px
}

.select2-container--default .select2-selection--multiple {
    border-width: 2px
}

.select2-container--open .select2-dropdown--below {
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5)
}

.select2-selection .select2-selection--multiple:after {
    content: "hhghgh"
}

.select-icon .select2-selection__placeholder .badge {
    display: none
}

.select-icon .select2-results__option:before,
.select-icon .select2-results__option[aria-selected=true]:before {
    display: none !important
}

.select-icon .select2-search--dropdown {
    display: none
}

.hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgb(75 85 99/var(--tw-text-opacity))
}

.hover\:underline:hover {
    text-decoration-line: underline
}

.hover\:opacity-50:hover {
    opacity: .5
}

/* Small screens */
@media screen and (max-width: 767px) {
    .sm\:hidden {
        display: none
    }

    .sm\:p-10 {
        padding: 2.5rem
    }
}

@media (min-width: 1280px) {
    .lg\:mr-0 {
        margin-right: 0
    }
}

:root {
    --itempos: 80px;
    --imageXpos: -150px;
    --imageYpos: 180px;
}

.tab-container {
    position: relative
}

.tab-container .tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: calc((99.2vw - 1440px) / 2);
    position: absolute;
    width: 25%;
    z-index: 40
}

.tab-container .tabs .tab {
    border: none;
    color: var(--sb-color-white);
    font-size: 24px;
    font-weight: 100;
    line-height: 80px;
    text-align: left;
    transition: all .3s ease-in-out;
    width: 100%;
    position: relative;
}

.tab-container.tab-container.our-process-tabs .tabs .tab {
    color: var(--sb-color-black)
}

.tab-container .tabs .tab:hover {
    padding-left: 60px
}

.tab-container .tabs .rings-menu-logo {
    opacity: 0;
    -webkit-transform: translateY(70px) rotate(400deg);
    transform: translateY(70px) rotate(400deg);
    transition: all .5s linear;
    position: absolute;
    top: 80px
}

.tab-container .tabs:hover .rings-menu-logo {
    opacity: 1
}

.tab-container .panel {
    background-color: #123465;
    display: none;
    justify-content: flex-end;
    min-height: 780px;
    max-height: 780px;
    overflow: hidden;
    padding: 0;
    position: relative
}

.tab-container .panels .panel .default {
    display: flex;
    flex-direction: column;
    flex-basis: 60%;
    justify-content: space-around;
    align-items: baseline;
    opacity: 0;
    transition: opacity .4s linear;
}

.tab-container:not(:hover) .panels .panel .default {
    opacity: 1;
}

.tab-container .panels .panel .default h2 {
    font-size: 40px;
    color: var(--sb-color-white);
    margin-bottom: 1em;
}

.tab-container .panels .panel .default .service-description {
    font-size: 22px;
    color: var(--sb-color-white);
    font-weight: 100;
}

.tab-container:hover .panel.active,
.tab-container:not(:hover) .panel:first-child {
    display: flex
}

.xxtab-container .panel:first-child {
    -webkit-animation: displayPanelTab .3s ease-in-out;
    animation: displayPanelTab .3s ease-in-out
}

.tab-container:hover .panel:first-child {
    opacity: 0;
}

.tab-container.our-process-tabs:hover .panel.active,
.tab-container.our-process-tabs:not(:hover) .panel:first-child {
    -webkit-animation: displayPanelTab .5s ease-in-out;
    animation: displayPanelTab .5s ease-in-out
}

.tab-container:not(.our-process-tabs) .panel:not(:first-child) .service-description {
    background-color: rgba(0, 0, 0, .5);
    border: 2px solid var(--sb-color-white);
    border-radius: 10px;
    bottom: 10%;
    color: var(--sb-color-white);
    padding: 20px;
    position: absolute;
    right: 5%;
    width: 60%
}

.tab-container:not(.our-process-tabs) .panel:not(:first-child) .service-description p {
    color: var(--sb-color-white);
}

main .tab-container .panel:not(:first-child) .service-image {
    -webkit-animation: tab-image-down .8s ease;
    animation: tab-image-down .8s ease;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    object-fit: contain
}

main .tab-container .panel.active:not(.ux) .service-image {
    animation: tab-image-up .8s ease;
    animation-fill-mode: forwards
}
main .tab-container .panel.active.ux .service-image {
    object-fit: cover;
}
main .common-section.choose-us .column-wrapper {
   margin-top: 2em;
}
main .tab-container .panel.active.integrations .service-image {
    --imageXpos: 250px;
    animation: tab-image-up .8s ease;
    animation-fill-mode: forwards
}
main .tab-container .panel.active.panel.app-development .service-image {
    margin-bottom: -120px;
}

main .tab-container .panel.ux.active .service-image {
    -webkit-animation: tab-image-ux-up .8s ease;
    animation: tab-image-ux-up .8s ease;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

main .tab-container .panel:not(:first-child).digital-strategy .service-image {
    min-height: 700px;
    object-fit: none
}

main .tab-container .panel.capabilities {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue))
}

main .tab-container .panel.branding {
    background: linear-gradient(78.66deg, #53287e, var(--sb-color-light-blue))
}

main .tab-container .panel.digital-strategy {
    background: linear-gradient(78.66deg, #6c79a3, var(--sb-color-light-blue))
}

main .tab-container .panel.ux {
    background: linear-gradient(78.66deg, #286339, var(--sb-color-light-blue))
}

main .tab-container .panel.web-development {
    background: linear-gradient(78.66deg, #53287e, var(--sb-color-light-blue))
}

main .tab-container .panel.app-development {
    background: linear-gradient(78.66deg, #0098db, var(--sb-color-light-blue))
}

main .tab-container .panel.maintenance {
    background: linear-gradient(78.66deg, #a32828, var(--sb-color-light-blue))
}

main .tab-container .panel.integrations {
    background: linear-gradient(78.66deg, #35003E,#D53871)
}

main .tab-container .panel.capabilities {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue))
}

/* .mobile-carousel */

main .display-block {
    display: block;
}

main .hide-block {
    display: none;
}

#root main .mobile-carousel .inner-slider {
    padding-top: 3em;
}
#root main .mobile-carousel .inner-slider .carousel-root {
    padding-top: 2em;
}
main .mobile-carousel .inner-slider .slider-title {
    padding-bottom: 1em;
    border-bottom: 1px solid var(--sb-color-white);
    color: var(--sb-color-white);
}

main .mobile-carousel .inner-slider .slider-title .slider-copy p {
    color: var(--sb-color-white);
}

main .mobile-carousel .inner-slider.branding {
    background: linear-gradient(78.66deg, #53287e, var(--sb-color-light-blue))
}

main .mobile-carousel .inner-slider.digital-strategy {
    background: linear-gradient(78.66deg, #6c79a3, var(--sb-color-light-blue))
}

main .mobile-carousel .inner-slider.user-experience-design {
    background: linear-gradient(78.66deg, #286339, var(--sb-color-light-blue))
}

main .mobile-carousel .inner-slider.web-development {
    background: linear-gradient(78.66deg, #53287e, var(--sb-color-light-blue))
}

main .mobile-carousel .inner-slider.app-dev {
    background: linear-gradient(78.66deg, #0098db, var(--sb-color-light-blue))
}

main .mobile-carousel .inner-slider.maintenance {
    background: linear-gradient(78.66deg, #a32828, var(--sb-color-light-blue))
}

main .mobile-carousel .inner-slider.integrations {
    background: linear-gradient(78.66deg, #35003E,#D53871)
}
main .mobile-carousel .inner-slider.our-process-bg-color {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
}

main .mobile-carousel .carousel-slider .control-arrow {
    bottom: unset;
    width: 29px;
    ;
}

main .mobile-carousel .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: block;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    content: '';
}

main .mobile-carousel .carousel.carousel-slider .control-prev.control-arrow {
    left: unset;
    right: 40px;
}

main .mobile-carousel .carousel.carousel-slider .control-next.control-arrow:before {
    border-left: 15px solid var(--sb-color-white);
}

main .mobile-carousel .carousel.carousel-slider .control-prev.control-arrow:before {
    border-right: 15px solid var(--sb-color-white);
}

main .mobile-carousel .carousel-slider .control-arrow:hover {
    background: none;
}

main .mobile-carousel .carousel-slider .slider {
    align-items: flex-start;
    background-color: transparent;
}

main .mobile-carousel .carousel-slider .slide .slide-data {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}
main .mobile-carousel:not(.our-process) .carousel-slider .slide .slide-data img {
    order: 1;
}
main .mobile-carousel .carousel-slider .slide .slide-data h4 {
    font-size: 20px;
    color: var(--sb-color-white);
    margin-bottom: 1em;
}
#root main .mobile-carousel.our-process .slider-copy p {
    color: var(--sb-color-white);
    margin-bottom: 1em;
}
main .mobile-carousel .carousel-slider .slide .slide-title p,
main .mobile-carousel .carousel-slider .slide .slide-data p {
    line-height: 28px;
    color: var(--sb-color-white);
    margin-bottom: 1em;
}

main .mobile-carousel.our-process .slider .slide img {
    max-width: 85px;
}

main .mobile-carousel.our-process .carousel-slider .slide .slide-data h4 {
    margin-top: 1em;
    flex-basis: 100%;
}

main .our-process-boxes .info-box-wrapper {
    align-items: flex-start;
}

main .our-process-boxes .info-box-wrapper .info-box {
    padding: 2em 1em;
    cursor: pointer;
    position: relative;
}

main .our-process-boxes .info-box-wrapper .info-box.active {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    flex-basis: 45%;
    animation: slide-box-in .8s;
    margin-right: 2em;
    padding: 1.5em;
}

main .our-process-boxes .info-box-wrapper .info-box.active.slideleft {
    animation: slide-left-box-in .8s;
}

main .our-process-boxes .info-box-wrapper .info-box.active .arrow {
    content: "";
    position: absolute;
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    top: 50%;
    left: -30px;
    width: 48px;
    height: 24px;
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    transform: rotate(270deg) translateY(-50%);
    z-index: 99;
}

main .our-process-boxes .info-box-wrapper .info-box.active .arrow.right {
    right: -30px;
    left: unset;
    transform: rotate(90deg) translateY(-50%);
}

main .our-process-boxes .info-box-wrapper .info-box:nth-child(1).active .arrow.left {
    display: none;
}

main .our-process-boxes .info-box-wrapper .info-box .info-description {
    display: none;
    color: var(--sb-color-white);
}

main .our-process-boxes .info-box-wrapper .info-box.active .info-description {
    display: block;
}

main .our-process-boxes .sub-heading {
    font-size: 20px;
    color: var(--sb-color-light-blue);
    margin-bottom: 3em;
}

main .our-process-boxes .info-box-wrapper .info-box.active h3 {
    color: var(--sb-color-white);
}

main .our-process-boxes .info-box-wrapper .info-box h3 span {
    font-weight: 100;
}

main .our-process-boxes .info-box-wrapper .info-box img {
    min-height: 87px;
}

main .our-process-boxes .info-box-wrapper .info-box.active img {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-image: url(https://a.storyblok.com/f/229508/71x87/525c90e412/proces-icon-discovery.svg);
    background-repeat: no-repeat;
    width: 100px;
    height: 90px;
    padding-left: 100px;
}

main .our-process-boxes .info-box-wrapper .info-box:nth-child(2).active img {
    background-image: url(https://a.storyblok.com/f/229508/81x87/f94052e167/proces-icon-design.svg);
}

main .our-process-boxes .info-box-wrapper .info-box:nth-child(3).active img {
    background-image: url(https://a.storyblok.com/f/229508/95x79/01df6daa36/proces-icon-development.svg);
}

main .our-process-boxes .info-box-wrapper .info-box:nth-child(4).active img {
    background-image: url(https://a.storyblok.com/f/229508/92x77/b4f5a1e9ff/proces-icon-qa.svg);
}

main .our-process-boxes .info-box-wrapper .info-box:nth-child(5).active img {
    background-image: url(https://a.storyblok.com/f/229508/77x71/5093fa4a72/proces-icon-publishing.svg);
}

main .our-process-boxes .info-box-wrapper .info-box:nth-child(6).active img {
    background-image: url(https://a.storyblok.com/f/229508/84x74/7662b22621/process-maintenance.svg);
}

@keyframes slide-box-in {
    from {
        transform: translateX(-30%);
        opacity: 0.25;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-left-box-in {
    from {
        transform: translateX(30%);
        opacity: 0.25;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

main .mobile-growth .column-wrapper .column {
    padding: 1.5em;
}

main .mobile-growth .column-wrapper .column .copy {
    max-height: 0;
    overflow: hidden;
    display: block;
    transition: all .6s ease-in-out;
}

main .mobile-growth .column-wrapper .column .column-heading {
    position: relative;
    padding-left: 5em;
    font-size: 20px;
}

main .mobile-growth .column-wrapper .column .column-heading::before {
    content: "Q";
    position: absolute;
    top: -5px;
    left: 0;
    font-size: 62px;
    line-height: 62px;
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(#7fc6a4, var(--sb-color-light-blue));
    -webkit-background-clip: text;
}

main .mobile-growth .column-wrapper .column .column-heading::after {
    content: "";
    position: absolute;
    left: 3.8em;
    height: 100%;
    top: 0px;
    background: -webkit-linear-gradient(#7fc6a4, var(--sb-color-light-blue));
    width: 4px;
}

main .mobile-growth .column-wrapper .column::before {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .3s linear;
    width: 100%;
}

main .mobile-growth .column-wrapper .column:hover::before {
    opacity: 1;
}

main .mobile-growth .column-wrapper .column:hover .column-heading,
main .mobile-growth .column-wrapper .column:hover .copy p {
    color: var(--sb-color-white);
    position: relative;
}

main .mobile-growth .column-wrapper .column:hover .column-heading::before {
    -webkit-text-fill-color: var(--sb-color-white);
}

main .mobile-growth .column-wrapper .column:hover .column-heading::after {
    background: var(--sb-color-light-blue);
}

main .mobile-growth .column-wrapper .column:hover .copy {
    max-height: 400px;
}

main .wearable-apps {
    padding: 5em 0 12em 0;
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
}

main .wearable-apps .column-wrapper .column:nth-child(1) {
    flex-basis: 65%;
}

main .wearable-apps .column-wrapper .column:nth-child(2) {
    flex-basis: 35%;
    justify-content: flex-end;
}

main .wearable-apps .column-wrapper .column:nth-child(1) h3,
main .wearable-apps .column-wrapper .column:nth-child(1) .copy p {
    color: var(--sb-color-white);
    margin-bottom: 1em;
}

main .wearable-apps .column-wrapper .column:nth-child(1) .copy p span {
    font-size: 22px;
}

main .need-custom-app {
    position: relative;
    margin-top: -12em;
}

main .need-custom-app .container {
    display: flex;
    flex-wrap: wrap;
}

main .need-custom-app .container .heading {
    order: 1;
    margin-top: 4em;
}

main .need-custom-app .container .column-wrapper .column {
    flex-basis: 33%;
    background-color: #D9D9D9;
    padding: 1.5em;
}

@-webkit-keyframes tab-image-up {
    0% {
        opacity: 0;
        transform: translateX(var(--imageXpos)) translateY(var(--imageYpos))
    }

    to {
        opacity: 1;
        transform: translateX(var(--imageXpos)) translateY(20px)
    }
}

@keyframes tab-image-up {
    0% {
        opacity: 0;
        transform: translateX(var(--imageXpos)) translateY(var(--imageYpos))
    }

    to {
        opacity: 1;
        transform: translateX(var(--imageXpos)) translateY(20px)
    }
}

@keyframes tab-image-down {
    0% {
        opacity: 1;
        transform: translateX(var(--imageXpos)) translateY(0)
    }

    to {
        opacity: 0;
        transform: translateX(var(--imageXpos)) translateY(var(--imageYpos))
    }
}

@-webkit-keyframes tab-image-ux-up {
    0% {
        opacity: 0;
        transform: translateX(0) translateY(var(--imageYpos))
    }

    80% {
        opacity: 1
    }

    to {
        transform: translateX(100px) translateY(0)
    }
}

@keyframes tab-image-ux-up {
    0% {
        opacity: 0;
        -webkit-transform: translateX(0) translateY(var(--imageYpos));
        transform: translateX(0) translateY(180px)
    }

    80% {
        opacity: 1
    }

    to {
        -webkit-transform: translateX(100px) translateY(0);
        transform: translateX(100px) translateY(0)
    }
}

.tab-section.our-process-tabs {
    padding: 3em 0;
}

.tab-container.our-process-tabs {
    align-items: center;
    counter-reset: listitem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    min-height: 800px;
    gap: 2em;
}

.tab-container.our-process-tabs .tabs {
    position: static
}

.tab-container.our-process-tabs .tabs .tab:before {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(36.99deg, var(--sb-color-dark-blue) 16.24%, var(--sb-color-light-blue) 40.76%);
    -webkit-background-clip: text;
    content: counter(listitem) ".";
    counter-increment: listitem;
    font-size: 24px;
    font-weight: 900;
    margin-right: 5px
}

.tab-container.our-process-tabs .panels {
    flex-basis: 75%;
    padding: 20px 0
}

.tab-container.our-process-tabs .panel {
    background-color: initial;
    padding-left: 5%;
}

.tab-container.our-process-tabs .panel .service-description {
    color: var(--sb-color-black);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.tab-container.our-process-tabs .panel .service-image {
    display: none;
}

.tab-container.our-process-tabs .panel .service-description p {
    margin-bottom: 1.5em;
    font-size: 16px;
}

.tab-container.our-process-tabs .panel .service-description ul li p {
    margin-bottom: 0;
}

.tab-container.our-process-tabs .panel:first-child .service-description h2 {
    color: #b5b5b5;
    font-size: 56px;
    font-weight: 100;
    margin-bottom: 30px;
}

.tab-container.our-process-tabs .panel .service-description ul {
    margin-right: 2em;
    margin-bottom: 1em;
}

.tab-container.our-process-tabs .tabs .rings-menu-logo {
    -webkit-filter: invert(11%) sepia(23%) saturate(3615%) hue-rotate(180deg) brightness(21%) contrast(104%);
    filter: invert(11%) sepia(23%) saturate(3615%) hue-rotate(180deg) brightness(21%) contrast(104%)
}

.tab-services {
    padding-bottom: 5em;
    padding-top: 5em;
}

.tab-services .tab-services-wrapper {
    min-height: 500px;
}

.tab-services .tab-services-wrapper .tabs {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.tab-services .tab-services-wrapper .tabs .tab {
    white-space: pre-wrap;
    border-left: 6px solid var(--sb-color-dark-blue);
    padding-left: 1em;
}

.tab-services .tab-services-wrapper .tabs .tab h3 {
    font-size: 30px;
    font-weight: 900;
    margin-left: -0.2em;
    text-align: left;
    transition: all .4s linear;
}

.tab-services .tab-services-wrapper .tabs .tab.active {
    border-color: var(--sb-color-light-blue);
}

.tab-services .tab-services-wrapper .tabs .tab.active h3 {
    margin-left: 1em;
}

.tab-services .tab-services-wrapper .tabs .tab .tab-icon-container {
    width: 0;
    transition: width .3s linear;
}

.tab-services .tab-services-wrapper .tabs .tab.active .tab-icon-container {
    width: 25%;
}

.tab-services .tab-services-wrapper .panels {
    background: linear-gradient(78.66deg, var(--sb-color-dark-blue), var(--sb-color-light-blue));
    flex-basis: 70%;
}

.tab-services .tab-services-wrapper .panels .panel {
    background-repeat: no-repeat;
    background-position: 110% 15%;
    color: var(--sb-color-white);
    display: none;
    padding-left: 2em;
}

.tab-services .tab-services-wrapper.mobile-apps .panels .panel {
    background-position: 119% 0;
}

.tab-services .tab-services-wrapper .panels .panel.active {
    animation: displayPanelTab .5s ease-in-out;
    display: block;
}

.tab-services .tab-services-wrapper .panels .panel p {
    color: var(--sb-color-white);
    font-size: 16px;
}

.tab-services .tab-services-wrapper .panels .panel h4 {
    font-size: 20px;
    font-weight: 900;
}

.tab-services .tab-services-wrapper .panels .panel h2 {
    font-size: 30px;
}

.tab-services .tab-services-wrapper .panels .panel .panel-title-container,
.tab-services .tab-services-wrapper .panels .panel .icons {
    max-width: 80%;
}

.tab-services .tab-services-wrapper .panels .panel .panel-title-container {
    max-width: 80%;
    padding-top: 2em;
}

.tab-services .tab-services-wrapper .panels .panel .icons {
    padding-top: 2em;
}

.tab-services .tab-services-wrapper .panels .panel .icons .icon-wrapper img {
    filter: grayscale(1);
    cursor: pointer;
}

.tab-services .tab-services-wrapper .panels .panel .icons .icon-wrapper.active img {
    filter: grayscale(0);
}

.tab-services .tab-services-wrapper .panels .panel .description-wrapper {
    max-width: 80%;
    padding: 2em 0;
    display: grid;
    overflow: hidden;
}

.tab-services .tab-services-wrapper .panels .panel .description-wrapper .description-row {
    grid-column: 1;
    grid-row: 1;
    opacity: 0;
    transition: opacity .3s linear;
}

.tab-services .tab-services-wrapper .panels .panel .description-wrapper .description-row.active {
    opacity: 1;
}

.tab-services .tab-services-wrapper .panels .panel .list-wrapper {
    padding: 2em 0;
}

.tab-services .tab-services-wrapper .panels .panel .list-wrapper .list ul li p {
    line-height: 3em;
    padding-left: 3em;
    position: relative;
}

.tab-services .tab-services-wrapper .panels .panel .list-wrapper .list ul li p::before {
    background-image: url(./images/icon-check.svg);
    background-size: cover;
    content: "";
    height: 30px;
    width: 30px;
    left: 0;
    position: absolute;
    top: 10px;
    
}

.tab-services .tab-services-wrapper.mobile-apps .panels .panel {
    position: relative;
}



.slider {
    background-color: var(--sb-color-light-grey);
    padding: 5em ;
    position: relative
}

.slider .inner-slider {
    padding-bottom: 30px;
    position: relative
}

.slider .inner-slider .slider-track {
    display: inline-flex;
    padding-bottom: 20px;
    padding-top: 20px;
    transition: all .3s ease-in-out 0s;
    width: 100%
}

.slider .inner-slider .arrow {
    bottom: -30px;
    color: var(--sb-color-black);
    cursor: pointer;
    font-size: 3rem;
    position: absolute;
    right: 45%;
    -webkit-user-select: none;
    user-select: none;
    z-index: 10
}

.slider .inner-slider .arrow:before {
    content: url(./images/next.svg)
}

.slider .inner-slider .arrow.left-arrow {
    left: 45%
}

.slider .inner-slider .arrow.left-arrow:before {
    content: url(./images/prev.svg)
}

.slider .inner-slider .slide {
    flex-shrink: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: all .3s linear;
    flex-basis: 25%;
}

.slider .inner-slider .slide img {
    width: 100%;
}

.slider .inner-slider .slide .profile-content {
    background: linear-gradient(185deg, transparent 25%, rgba(0, 33, 62, .5) calc(29% + -1px), rgba(51, 101, 138, .6) calc(29% + 1px));
    bottom: 0;
    color: var(--sb-color-white);
    height: 0;
    opacity: 0;
    padding: 40px 20px 0;
    position: absolute;
    transition: all .3s linear;
    width: 100%
}

.slider .inner-slider .slide .profile-content .team-member-name {
    font-size: 20px
}

.slider .inner-slider .slide .profile-content .team-member-position {
    font-size: 14px
}

.slider .inner-slider .slide:hover .profile-content {
    height: 34%;
    opacity: 1
}

.slider .inner-slider .become-part {
    background: linear-gradient(78.66deg, var(--sb-color-black-pearl), var(--sb-color-dark-blue));
    border-bottom: 20px solid var(--sb-color-light-grey);
    border-top: 20px solid var(--sb-color-light-grey);
    color: var(--sb-color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    width: 288px
}

.slider .inner-slider .become-part h3 {
    font-size: 30px;
    margin-bottom: 30px
}

.slider .inner-slider .slide:hover {
    position: relative;
    -webkit-transform: scale(1.18);
    transform: scale(1.18);
    z-index: 30
}

.slider .slide:first-child,
.slider .slide:nth-child(5) {
    -webkit-transform-origin: left;
    transform-origin: left
}

.slider .slide:nth-child(4),
.slider .slide:nth-child(8) {
    -webkit-transform-origin: right;
    transform-origin: right
}

.column {
    position: relative
}

.column .column-heading {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 20px
}

.column .copy p {
    font-size: 16px
}

.form-section .form {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    justify-content: space-between;
}

.form-section.support .form {
    justify-content: flex-start;
    gap: 0 2em;
}

.form-section .form .input-container {
    display: flex;
    flex-basis: 23%;
    flex-direction: column;
    margin-top: 3em
}

.form-section .form .input-container label {
    color: var(--sb-color-dark-blue);
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase
}

.form-section .form .input-container input {
    border-bottom: 2px solid var(--sb-color-light-blue);
    border-radius: 4px;
    padding: 15px 10px
}

.form-section .form .input-container input:focus {
    outline: unset;
}

.form-section .form .input-container.full-width {
    flex-basis: 100%
}

.form-section .form .input-container .field-error {
    font-size: 0;
    transition: all .4s linear;
}

.form-section .form .input-container .field-error.has-error {
    font-size: 16px;
}

.form-section .form .css-1p3m7a8-multiValue:not(:first-child) {
    display: none;
}

.form-section .form .css-b62m3t-container {
    padding-top: 25px;
}

.form-section .form .css-b62m3t-container .css-13cymwt-control {
    border: none;
    border-bottom: 2px solid var(--sb-color-light-blue)
}

.form-section .form .css-b62m3t-container .css-13cymwt-control .css-1u9des2-indicatorSeparator {
    width: 0;
}

.form-section .form .css-b62m3t-container .css-1jqq78o-placeholder {
    font-weight: 100;
    font-size: 18px;
}

.form-section .form input::placeholder {
    font-weight: 100;
    font-style: italic;
}

.form-section .form-feedback {
    border: 1px solid;
    margin-bottom: 4em;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
}

.form-section .form-feedback.success {
    opacity: 1;
    visibility: visible;
    border-color: #0ab056;
    padding: 20px 10px;
}

.form-section .form-feedback.error {
    opacity: 1;
    visibility: visible;
    border-color: #922004;
    padding: 20px 10px;
}

main .mapWrapper .mapMarker {
    position: relative;
    background-image: url(./images/rings-dark.svg);
    padding: 2em;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 50%;
}
main .mapWrapper .mapMarker .infoWindow {
    background: #1496bb;
    bottom: 100%;
    color: #fff;
    display: block;
    left: -7em;
    margin-bottom: 15px;
    opacity: 0;
    padding: 1.5em 4em;
    pointer-events: none;
    position: absolute;
    /* width: 100%; */
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}
main .mapWrapper .mapMarker .infoWindow.show {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}
main .mapWrapper .mapMarker .infoWindow::before {
    bottom: -20px;
    content: " ";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
}
main .mapWrapper .mapMarker .infoWindow::after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #1496bb 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
}
main .mapWrapper .mapMarker .infoWindow a {
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    text-align: center;
}

main .mapWrapper .mapMarker .infoWindow p {
    font-size: 14px;
    white-space: nowrap;
}
main .integrations-are-key .column .text-wrapper .copy p {
    font-size: 18px;
}

/* POSTS */

main .blog-wrapper {
    padding: 4em 0;
}

main .blog-wrapper .post-list {
    gap: 10em 3em;
}

main .blog-wrapper .post-list .post-item {
    flex-basis: 100%;
    position: relative;
}

main .blog-wrapper .post-list .post-item .post-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
}

main .blog-wrapper .post-list .post-item .post-content .post-meta {
    padding: 10px 0;
    color: var(--sb-color-light-blue);
    font-size: 12px;
}

main .blog-wrapper .post-list .post-item .post-content .post-title {
    color: var(--sb-color-dark-blue);
    font-size: 22px;
}

main .blog-wrapper .post-list .post-item .post-content .post-copy {
    color: var(--sb-color-black);
    font-size: 18px;
    padding-bottom: 2em;
}



main .blog-wrapper .post-list .post-item.recent {
    flex-basis: 30%;
}

main .blog-wrapper .post-list .post-item.recent .post-copy {
    display: none;
}

main .blog-wrapper .post-list .post-item.recent .post-content {
    display: block;
    position: relative;
    height: 100%;
}

main .blog-wrapper .post-list .post-item.recent .post-content::after {
    content: "";
    background: linear-gradient(to top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 66%, rgba(0, 0, 0, 0) 100%) !important;
    height: 50%;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
}

main .blog-wrapper .post-list .post-item.recent .post-content .post-title,
main .blog-wrapper .post-list .post-item.recent .post-content .post-meta {
    position: absolute;
    bottom: 10px;
    padding: 0 20px;
    color: var(--sb-color-white);
    z-index: 30;
}

main .blog-wrapper .post-list .post-item.recent .post-content .post-meta {
    bottom: 100px;
}

main .blog-wrapper .post-list .post-item.recent .post-content .post-image {
    object-fit: cover;
    height: 100%;
}

main .blog-wrapper .post-list .post-item.recent .post-content .post-meta .post-cat {
    padding: 10px;
    background: var(--sb-color-light-blue);
    border-radius: 30px;
}

main .blog-wrapper .post-list .post-item .post-content .post-meta .post-cat {
    margin-right: 1em;
}

main .blog-wrapper .post-list .post-item .post-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 3em;
}

main .blog-wrapper .post-list .post-item .post-content img {
    grid-row: 1 / 4;
}

main .blog-wrapper .post-list .post-item .post-content .post-title,
main .blog-wrapper .post-list .post-item .post-content .post-meta,
main .blog-wrapper .post-list .post-item .post-content .post-copy {
    grid-column: 2 / 4;
}
